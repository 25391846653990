import { decorate, observable, action } from 'mobx';
import { metadataUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class MetadataStore {
    state = PENDING;

    workOrderId = -1;
    fields = [];
    fieldValues={};

    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    fetchMetadata = async (workOrderId) => {
        this.state = PENDING;
        this.workOrderId = workOrderId;

        try {
            const url = metadataUrl.replace('{id}', workOrderId)
            const response = await this.apiProxy.getAsync(url);
            const data = await response.json();
    
            this.fields = JSON.parse(data.fields).fields;
            this.fieldValues = JSON.parse(data.fieldValues);
            this.state=DONE;
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        };

    }

    updateWorkOrderFields = async(values) => {
        this.state = PENDING;
        this.fieldValues = values;
        try {
            const url = metadataUrl.replace('{id}', this.workOrderId)
            const response = await this.apiProxy.asyncPost(url,  this.fieldValues );
            const data = await response.json();
            
            this.fields = JSON.parse(data.fields).fields;
            this.fieldValues = JSON.parse(data.fieldValues);
            this.state=DONE;
        }
        catch (e) {
            this.state = ERROR;
        }
    }


}

decorate(MetadataStore, {
    state: observable,
    fields: observable,
    fieldValues: observable,
    fetchMetadata: action,
    updateWorkOrderFields:action
})

