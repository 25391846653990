import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge,Icon } from 'antd';
import PeopleModal from './PeopleModal';


const columnDefs = [
    { headerName: 'Title',field:'salutation'},
    { headerName: 'First Name', field: 'firstName' },
    { headerName: 'Middle Name', field: 'middleName' },
    { headerName: 'Last Name', field: 'lastName' },
    { headerName: 'Corres', field: 'corres' },
    { headerName: 'Email', field: 'email' }
];

@observer
class PeopleList extends Component {
    constructor(props) {
        super(props);
    }

    next = () => {
        this.props.workOrderStore.changeTab('4');
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.peopleSelected);
        params.api.sizeColumnsToFit();

        this.props.workOrderStore.peopleListStore.fetchPeople();
    }

    refresh = () => {
        this.props.workOrderStore.peopleListStore.fetchPeople();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.5;
        return { height: height };
    }

    peopleSelected = (params) => {
        this.props.workOrderStore.peopleStore.fetchPerson(params.data.articleWorkOrderId,params.data.id);
    }

    newPerson = () => {
        this.props.workOrderStore.peopleStore.newPerson()
    }

    render() {
        const store = this.props.workOrderStore.peopleListStore;
        const people = store.people;

        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button onClick={this.newPerson} key="add">Add People</Button>,
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount}>
                            <Button icon="mail" />
                        </Badge>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(people)}
                
                <Button onClick={this.next} type="primary" >Next <Icon type="right" /></Button>
                <PeopleModal peopleStore = {this.props.workOrderStore.peopleStore}/>
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    />
                </div>
            );
        }
    }

    renderRowData(people) {
        if (this.gridApi) {
            this.gridApi.setRowData(people);
        }
    }

}

export default PeopleList;