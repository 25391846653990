import { action, decorate, observable } from 'mobx';
import APIProxy from './APIProxy';
import LoginStore from './LoginStore';
import {isBlank} from './Util';
import { autoLoginUrl } from './APIEndpoints';
import {toUrlParams} from "../stores/Util";


const blankCredentials = {
    email: '',
    token: '',
    role: '',
    username: '',
    userId:0
}

const INIT = 'init';
const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

const IN_PROGRESS_MESSAGE = 'Authenticating...';
const SUCCESS = 'Authentication is successful.';
const INVALID_CREDENTIAL = 'Invalid Login Information';

class AppStore {

    credentials = blankCredentials;

    currentComponent = null;

    apiProxy = new APIProxy();
    loginStore = new LoginStore({ apiProxy: this.apiProxy });
    menus = [];

    validationMessage = '';
    isEditable=true;
    state=INIT;

    constructor() {
        this.setSessionFromStorage();
    }

    setSessionFromStorage = () => {
        const storedCredentials = localStorage.getItem('credentials');

        if (storedCredentials !== null) {
            this.credentials = JSON.parse(storedCredentials);
            this.updateContext();
        }
        else {
            this.credentials = blankCredentials;
            this.updateContext();
        }
    }

    autoAuthenticate = async (queryParams) => {

        this.notifyProgress();

        this.credentials = blankCredentials;

        const response = await this.apiProxy.asyncPost(autoLoginUrl + '?' + toUrlParams(queryParams));
        const data = await response.json();
          console.log(data)
     
        if (data == null || data.token == null) {
            this.notifyError(INVALID_CREDENTIAL);
            return;
        }

        this.credentials.email = data.email;
        this.credentials.token = data.token;
        this.credentials.role = data.role;
        this.credentials.username = data.name;
        this.credentials.userId = data.user_id;


        this.apiProxy.updateCredentialHeaders(this.credentials);
 
        this.updateContext();
        this.persistCredentials();
        this.notifySuccess();
    }
   
    authenticate = async () => {

        this.notifyProgress();

        this.credentials = blankCredentials;

        const data = await this.loginStore.authenticate();
     
        if (data == null || data.token == null) {
            this.notifyError(INVALID_CREDENTIAL);
            return;
        }

        this.credentials.email = data.email;
        this.credentials.token = data.token;
        this.credentials.role = data.role;
        this.credentials.username = data.name;
        this.credentials.userId = data.user_id;


        this.apiProxy.updateCredentialHeaders(this.credentials);
 
        this.updateContext();
        this.persistCredentials();
        this.notifySuccess();
    }

    notifyError = (errorMessage) => {
        this.state = ERROR;
        this.isEditable = true;
        this.validationMessage = errorMessage;
    }

    notifyProgress = () => {
        this.state = PENDING;
        this.isEditable = false;
        this.validationMessage = IN_PROGRESS_MESSAGE;
    }

    notifySuccess = () => {
        this.state = DONE;
        this.isEditable=false;
        this.validationMessage = SUCCESS;
    }


    persistCredentials = () => {
        localStorage.setItem('credentials', JSON.stringify(this.credentials));
    }

    updateContext = () => {
        this.apiProxy.updateCredentialHeaders(this.credentials);
        this.resolveMenu();
        this.resolveLandingPage();
    }

    resolveMenu() {
        if (!this.isLoggedIn()) {
            this.menus = require('./menus/GuestMenu.json');
            return;
        }

        switch (this.credentials.role) {
            case 'Ops':
                {
                    this.menus = require('./menus/OpsMenu.json');
                    break;
                }
            case 'ProjectManager':
                {
                    this.menus = require('./menus/PmMenu.json');
                    break;
                }    
            case 'SuperProjectManager':
                {
                    this.menus = require('./menus/PmMenu.json');
                    break;
                } 
            default:
                {
                    this.menus = require('./menus/DefaultRoleMenu.json');
                }
        }
    }

    resolveLandingPage() {
        this.menus.map((menu, key) => {
            if (menu.isLandingPage) {
                this.transitionTo(menu.key);
                return;
            }
        })
    }

    transitionTo(componentkeyName) {
        this.menus.map((menu) => { (menu.key === componentkeyName) && (this.currentComponent = menu) });
    }

    isLoggedIn = () => {
        return !(isBlank(this.credentials.email) || isBlank(this.credentials.token))
    }

    logout = () => {
        localStorage.setItem('credentials', JSON.stringify(blankCredentials));
        window.location.reload();
    }

    setProxy = (apiProxy) =>{
        this.apiProxy = apiProxy;
    }
}

decorate(AppStore, {
    state:observable,
    validationMessage: observable,
    isEditable:observable,
    menus: observable,

    currentComponent: observable,
  
    credentials: observable,
    setCredentials: action,
    logout: action,

    authenticate: action,
    transitionTo: action
});

export const appStore = new AppStore();