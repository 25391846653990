import { decorate, observable, flow, action } from 'mobx';

import { workOrderFigureListUrl, workOrderFigureUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

const freshFigure = {
    id: 0,
    articleWorkOrderId: -1,
    figureCode: '',
    colorPrint: false,
    colorOnline: false,
}

export default class FigureStore {

    figure = {};
    showModal = false;

    constructor(props) {
        this.apiProxy = props.apiProxy;
        this.figureListStore = props.figureListStore;
    }

    newFigure = () => {
        console.log('fresh figure');
        this.figure = freshFigure;
        this.figure.articleWorkOrderId = this.figureListStore.workOrderId;
        this.state = DONE;
        this.showModal = true;
    }

    fetchFigure = async (articleWorkOrderId, articleWorkOrderFigureId) => {

        this.state = PENDING;
        try {

            const url = workOrderFigureUrl.replace('{id}', articleWorkOrderId).replace('{figureId}', articleWorkOrderFigureId);
            const response = await this.apiProxy.getAsync(url);
            const data = await response.json();

            this.figure = data;
            this.state = DONE;
            this.showModal = true;
        }
        catch (e) {
            this.state = ERROR;
        }
    }

    saveFigure = async (values) => {

        this.state = PENDING;

        try {

            const url = workOrderFigureListUrl.replace('{id}', this.figure.articleWorkOrderId);

            const response = await this.apiProxy.asyncPost(url, { id: this.figure.id, ...values });
            const data = await response.json();

            this.figure.id = data.id;
            this.figureListStore.fetchFigureList();
            this.state = DONE;
            this.showModal = false;
        }
        catch (e) {
            this.state = ERROR;
        }
    }

    deleteFigure = async () => {

        this.state = PENDING;

        console.log("deleting this fiugre");

        try {

            const url = workOrderFigureUrl.replace('{id}', this.figure.articleWorkOrderId).replace('{figureId}', this.figure.id);

            const response = await this.apiProxy.asyncDelete(url);
            const data = await response.json();

            this.figure.id = data.id;
            this.figureListStore.fetchFigureList();
            this.state = DONE;
            this.showModal = false;
        }
        catch (e) {
            this.state = ERROR;
            console.error(e);
        }
    }

}

decorate(FigureStore, {
    state: observable,
    figure: observable,
    showModal: observable,
    articleWorkOrderId: observable,

    newFigure: action,
    saveFigure: action,
    fetchFigure: action
})