import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button } from 'antd';
import PeopleForm from './PeopleForm';

@observer
class PeopleModal extends Component {

    handleCancel = () => {
        this.props.peopleStore.showModal = false;
    }

    deletePerson = () => {
        const store = this.props.peopleStore;
        store.deletePerson();
    }

    render() {
        const visible = this.props.peopleStore.showModal;

        return (
            <Modal title="Author Information"
                visible={visible}
                width= {600}
                onCancel={this.handleCancel}
                footer={[]}
                destroyOnClose={true}>

                <PeopleForm peopleStore = {this.props.peopleStore} destroyOnClose={true}/>

                <Button style={{float: 'right', bottom: '30px'}} onClick={this.deletePerson} type="warning" >Delete</Button>

            </Modal>
        )
    }
}
export default PeopleModal;