import { decorate, observable, flow } from 'mobx';

import { unreleasedWorkOrderUrl } from './APIEndpoints';
import Api from './APIEndpoints';
import {appStore} from './AppStore';


const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class WorkOrderListStore {

  workOrders = [];
  listState = PENDING;
  rowCount = 0;

  constructor(props) {
    this.apiProxy = props.apiProxy;
  }

  fetchWorkOrders = async values => {
    this.listState = PENDING;

    try {

      // const response = yield this.apiProxy.getAsync(unreleasedWorkOrderUrl);
      // const data = yield response.json();
      const params = { "where": { "userId": appStore.credentials.userId.toString()}, "limit": "5" }
      const data = await Api.unreleasedWorkOrderUrl.get(params);

      this.workOrders = data;
      this.rowCount = data.length;
      this.listState = DONE;
    }
    catch (e) {
      this.listState = ERROR;
    };
  };


}

decorate(WorkOrderListStore, {
  listState: observable,
  workOrders: observable,
  rowCount: observable
})
