import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button } from 'antd';
import FigureForm from './FigureForm';

@observer
class FigureModal extends Component {

    handleCancel = () => {
        this.props.figureStore.showModal = false;
    }

    deleteFigure = () => {
        const store = this.props.figureStore;
        store.showModal = false;        
        store.deleteFigure();
    }

    render() {
        const visible = this.props.figureStore.showModal;

        return (
            <Modal title="Figure Information"
                visible={visible}
                width= {600}
                onCancel={this.handleCancel}
                footer={[]}
                destroyOnClose={true}
            >
                <FigureForm figureStore = {this.props.figureStore} />

                <Button style={{float: 'right', bottom: '30px'}} onClick={this.deleteFigure} type="warning">Delete</Button>

            </Modal>
        )
    }
}

export default FigureModal;