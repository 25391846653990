import ApiUtil from './ApiUtil';

// a wrapper around the standart fetch which handles non 2XX status as exceptions
export const request = (url, params) => {
  return new Promise((respond, reject) => {
    fetch(url, params)
      .then(async res => {
        res.ok ? respond(res) : reject(res);
      })
      .catch(e => reject(e));
  });
};

export default class Http {
  baseUrl = '';
  getDeafultConfig = () => {};

  constructor(baseUrl, getDeafultConfig) {
    this.baseUrl = baseUrl;
    this.getDeafultConfig = getDeafultConfig;
  }

  _absUrl = url => `${this.baseUrl}${url}`;

  get = url => request(this._absUrl(url), this.getDeafultConfig());

  post = (url, body) =>
    request(
      this._absUrl(url),
      ApiUtil.mergeDeep({ method: 'POST', body: JSON.stringify(body) }, this.getDeafultConfig()),
    );
}
