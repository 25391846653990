import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Switch } from 'antd';
import { isEmpty,getUtcDate } from '../stores/Util.js'

import {Button, Form, Select, Input, Tooltip,Icon} from 'antd';

import moment from 'moment-timezone';
import { DatePicker } from 'antd';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

@observer
class WorkOrderForm extends Component {

    saveAndNext = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {

            const store = this.props.workOrderStore;

            let validForm = store.manuscriptNoStatus==="success";

            if(isEmpty(values.manuscriptNo))
            {
                store.manuscriptNoHelp = "Please enter the Manuscript Number"
                store.manuscriptNoStatus = "error";
            }

            if (!err && validForm) {
                store.savePrimaryInfo(values);
            }
        });
    }

    markAsDirty = (e) => {
        const store = this.props.workOrderStore;
        store.isPrimaryDirty = true;
    }

    onJournalSelected = (journalId) => {
   
        this.props.form.resetFields(["articleTypeId","journalArticleTypeId"]);

        const store = this.props.workOrderStore;
        store.fetchArticleTypes(journalId);

        this.markAsDirty();
    }

    onArticleTypeSelected = (articleTypeId) => {
        this.props.form.resetFields("journalArticleTypeId");

        const store = this.props.workOrderStore;
        store.fetchWorkflows(articleTypeId);

        this.markAsDirty();
    }

    onEmbargoSelected = (embargo) => {
        const store = this.props.workOrderStore;
        store.workOrder.embargo = embargo;

        this.markAsDirty();
    }

    onEditorsChoice = (choice) => {
        const store = this.props.workOrderStore;
        store.workOrder.editorsChoice = choice;

        this.markAsDirty();
    }

    onLinkedArticle = (choice) => {

        const store = this.props.workOrderStore;
        store.workOrder.linkedArticles = choice;

        this.markAsDirty();
    }

    onSupplementData = (choice) => {

        const store = this.props.workOrderStore;
        store.workOrder.supplementData = choice;

        this.markAsDirty();
    }
    onVoiceLogo = (choice) => {
        const customFields = {};
        customFields.voiceLogo = choice;
        const store = this.props.workOrderStore;
        store.workOrder.customFields = JSON.stringify(customFields);
        this.markAsDirty();
    }


    onWorkflowSelected = (journalArticleTypeId) => {
        const store = this.props.workOrderStore;
        store.workOrder.journalArticleTypeId = journalArticleTypeId;

        this.markAsDirty();
    }

    getEmbargoUtcDate = () => {

        let req_fields = this.props.form.getFieldsValue(["embargoDate","embargoTimezone"])

        let embargo = req_fields.embargoDate;
        let timezone = req_fields.embargoTimezone;

        if(embargo !== null && timezone !== "" )
        {
            return getUtcDate(embargo,timezone);
        }

        return "";
    }

    manuscriptStatus = () => {
        const store = this.props.workOrderStore;
        return store.manuscriptNoStatus;
    }

    manuscriptHelpText = () => {
        const store = this.props.workOrderStore;
        return store.manuscriptNoHelp;
    }

    renderJournals = (journals) => {
        
        const { Option } = Select;

        return (
            journals.map(item =>
                <Option value={item.journalId} key={item.journalId} >{item.name}</Option>
            )
        )
    }

    renderTimezones = () => {
        
        const { Option } = Select;

        const timezones = moment.tz.names();

        return (
            timezones.map(item =>
                <Option value={item} key={item} >{item}</Option>
            )
        )
    }

    renderWorkflows = (workflows) => {
        const { Option } = Select;

        return (
            workflows.map(item =>
                <Option value={item.id} key={item.id} >{item.workflowTriggerName}</Option>
            )
        )
    }

    renderArticleTypes = (articleTypes) => {
        const { Option } = Select;

        return (
            articleTypes.map(item =>
                <Option value={item.articleTypeId} key={item.articleTypeId} >{item.articleTypeName}</Option>
            )
        )
    }

    renderCustomArticleType = () => {
        const { getFieldDecorator } = this.props.form;

        const store = this.props.workOrderStore;
        const selectedWorkOrder = store.workOrder;
     //   const voiceLogo = JSON.parse(selectedWorkOrder.customFields)
        if(selectedWorkOrder.articleTypeId !== -1)
        {
            return <Fragment></Fragment>
        }

        return (
            <Form.Item
                label={
                    <span>
                        Custom Article Type&nbsp;
                        <Tooltip title="Please Specify this new Article Type">
                            <Icon type="question-circle-o" />
                        </Tooltip>
                    </span>
                }
            >
                {getFieldDecorator('customArticleType', {
                    initialValue: selectedWorkOrder.articleTypeName,
                    rules: [
                        {
                            required: true,
                            message: 'Please specify a Custom Article Type',
                            whitespace: true,
                        },
                    ],
                })(<Input onChange={this.markAsDirty}/>)}
            </Form.Item>
        )
    }

    renderEmbargoDate = () => {
        const { getFieldDecorator } = this.props.form;

        const store = this.props.workOrderStore;
        const selectedWorkOrder = store.workOrder;

        if(selectedWorkOrder.embargo === false)
        {
            return <Fragment></Fragment>
        }

        let date = selectedWorkOrder.embargoDate === null ? null : moment(selectedWorkOrder.embargoDate)

        return (
            <Fragment>
            <Form.Item label="Embargo Date">
                    {getFieldDecorator  ('embargoDate', {
                        initialValue: date, 
                        rules: [
                            {
                                required: true,
                                message: 'Please select Embargo date',
                            },
                        ],
                    })(
                        <DatePicker showTime placeholder="Select Time" />
                    )}
                </Form.Item>

                <Form.Item 
                label={
                    <span>
                        Time zone&nbsp;
                        <Tooltip title="Please select the timezone of embargo">
                            <Icon type="question-circle-o" />
                        </Tooltip>
                    </span>
                }
                >
                    {getFieldDecorator  ('embargoTimezone', {
                        initialValue: selectedWorkOrder.embargoTimezone, 
                        rules: [
                            {
                                required: true,
                                message: 'Please select the timezone',
                            },
                        ],
                    })(
                        <Select showSearch>
                            {this.renderTimezones()}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item label="Embargo Date(UTC)">
                    {getFieldDecorator  ('embargoDateUtc', {
                        initialValue: "", 
                    })(
                        <span  >
                            {this.getEmbargoUtcDate()}
                        </span>
                    )}
                </Form.Item>

            </Fragment>
        )
    }

    renderLinkedArticles = () => {
        const { getFieldDecorator } = this.props.form;

        const store = this.props.workOrderStore;
        const selectedWorkOrder = store.workOrder;

        if(selectedWorkOrder.linkedArticles === false)
        {
            return <Fragment></Fragment>
        }

        return (
            <Fragment>
            <Form.Item label="Linked Articles List">
                    {getFieldDecorator  ('linkedArticlesList', {
                        initialValue: selectedWorkOrder.linkedArticlesList, 
                        rules: [
                            {
                                required: true,
                                message: 'Please enter the list of linked articles',
                            },
                        ],
                    })(
                        <Input onChange={this.markAsDirty}/>
                    )}
                </Form.Item>
            </Fragment>
        )
    }

    validate = event => {
        const { value } = event.target;
        const store = this.props.workOrderStore;

        store.manuscriptNoStatus = "validating";
        store.manuscriptNoHelp = "The information is being validated...";

        if(store.workOrder.manuscriptNo === value)
        {
            store.manuscriptNoStatus = "success";  
            store.manuscriptNoHelp = "";
            return;
        }
        else{
            store.validateManuscriptNumber(value);
        }
        
    }

    render() {
        const store = this.props.workOrderStore;

        const selectedWorkOrder = store.workOrder;
        let voiceLogo=false;
        if(selectedWorkOrder.customFields == '' || selectedWorkOrder.customFields==null)
        {
            voiceLogo=false;
        }
        else{
            voiceLogo = JSON.parse(selectedWorkOrder.customFields).voiceLogo;
        }
       
        const journals = store.journals;
        const articleTypes = store.articleTypes;
       
        const { getFieldDecorator } = this.props.form;

        const disableSave = !store.isEditable;

        const workflows = store.workflows;

        const validationProps = {
            onBlur: this.validate
        };

        return (
            <Form {...formItemLayout} onSubmit={this.saveAndNext}>

                <Form.Item label="Journal">
                    {getFieldDecorator  ('journalId', {
                        initialValue: selectedWorkOrder.journalId === 0 ? '' : selectedWorkOrder.journalId, 
                        rules: [
                            {
                                required: true,
                                message: 'Please select the Journal that the article belongs to',
                            },
                        ],
                    })(
                        <Select showSearch onChange={this.onJournalSelected}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                            {this.renderJournals(journals)}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item label="Article Type">
                    {getFieldDecorator('articleTypeId', {
                        initialValue: selectedWorkOrder.articleTypeId === 0 ? '' : selectedWorkOrder.articleTypeId,
                        rules: [
                            {
                                required: true,
                                message: 'Please select the Article Type of the Journal',
                            },
                        ],
                    })(
                        <Select showSearch onChange={this.onArticleTypeSelected}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        >
                            {this.renderArticleTypes(articleTypes)}
                        </Select>
                    )}
                </Form.Item>

                {this.renderCustomArticleType()}

                <Form.Item label="Workflow">
                    {getFieldDecorator('journalArticleTypeId', {
                        initialValue: selectedWorkOrder.journalArticleTypeId === 0? '' : selectedWorkOrder.journalArticleTypeId,
                        rules: [
                            {
                                required: true,
                                message: 'Please select the Workflow for the selected Article Type of the Journal',
                            },
                        ],
                    })(
                        <Select onChange={this.onWorkflowSelected}>
                            {this.renderWorkflows(workflows)}
                        </Select>
                    )}
                </Form.Item>

                <Form.Item
                     label={
                         <span>
                             Manuscript Number&nbsp;
                         </span>
                     }
                    hasFeedback
                    validateStatus= {this.manuscriptStatus()}
                    help={this.manuscriptHelpText()}
                >
                     {getFieldDecorator('manuscriptNo', {
                         initialValue: selectedWorkOrder.manuscriptNo,
                         rules: [
                             {
                                 required: true,
                                 message: 'Please provide a manuscript number for identification',
                                 whitespace: false,
                             },
                         ],
                    })(<Input onChange={this.markAsDirty} {...validationProps}/>)}
                 </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Article Title&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('articleTitle', {
                        initialValue: selectedWorkOrder.articleTitle,
                        rules: [
                            {
                                required: true,
                                message: 'Please enter the article title',
                            },
                        ],
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            Volume No&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('volumNo', {
                        initialValue: selectedWorkOrder.volumeNo,
                        // rules: [
                        //     {
                              
                        //         required: true,
                        //         message: 'Please enter the volume no.',
                        //     },
                        // ],
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            Issue No&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('issueNo', {
                        initialValue: selectedWorkOrder.issueNo,
                        // rules: [
                        //     {
                        //         required: true,
                        //         message: 'Please enter the issue no.',
                        //     },
                        // ],
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Embargo &nbsp;
                            <Tooltip title="Please select if this is an Embargo Article">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('embargo', {
                        initialValue: selectedWorkOrder.embargo,
                    })(<Switch onChange={this.onEmbargoSelected} checked= {store.workOrder.embargo} /> )}
                    
                </Form.Item>
         
                {this.renderEmbargoDate()}

                <Form.Item
                    label={
                        <span>
                            Linked Article &nbsp;
                            <Tooltip title="Other article(s) linked to this article">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('linkedArticles', {
                        initialValue: selectedWorkOrder.linkedArticles,
                    })(<Switch onChange={this.onLinkedArticle} checked= {store.workOrder.linkedArticles} /> )}
                    
                </Form.Item>

                {this.renderLinkedArticles()}

                <Form.Item
                    label={
                        <span>
                            Editor's Choice &nbsp;
                            <Tooltip title="Please mark if it's an Editor's Choice article">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('editorsChoice', {
                        initialValue: selectedWorkOrder.editorsChoice,
                    })(
                    <Switch onChange={this.onEditorsChoice} checked= {store.workOrder.editorsChoice}/>
                     )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Supplement Data &nbsp;
                            <Tooltip title="Please indicate if Supplementary data are available">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('supplementData', {
                        initialValue: selectedWorkOrder.supplementData,
                    })(<Switch onChange={this.onSupplementData} checked= {store.workOrder.supplementData}/>)}
                    
                </Form.Item>


                <Form.Item
                    label={
                        <span>
                           Voice logo &nbsp;
                            <Tooltip title="Please indicate if Supplementary data are available">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('voiceLogo', {
                        initialValue: voiceLogo,
                    })(<Switch onChange={this.onVoiceLogo} checked= {voiceLogo}/>)}
                    
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Special Instructions&nbsp;
                            <Tooltip title="Any article-specific instructions to the team">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('notes', {
                        initialValue: selectedWorkOrder.notes,
                    })(<Input.TextArea rows={4} onChange={this.markAsDirty}/> )}
                </Form.Item>
                
                {/* <Form.Item label="Received Date">
                    {getFieldDecorator  ('receivedDate', {
                
                    })(
                        <DatePicker />
                    )}
                </Form.Item>

                <Form.Item label="Submitted Date">
                    {getFieldDecorator  ('submittedDate', {
                
                    })(
                        <DatePicker />
                    )}
                </Form.Item>

                <Form.Item label="Revised Date">
                    {getFieldDecorator  ('revisedDate', {
                
                    })(
                        <DatePicker />
                    )}
                </Form.Item>

                <Form.Item label="Accepted Date">
                    {getFieldDecorator  ('acceptedDate', {
                
                    })(
                        <DatePicker />
                    )}
                </Form.Item>

                <Form.Item label="EditorialDecision Date">
                    {getFieldDecorator  ('editorialDecisionDate', {
                
                    })(
                        <DatePicker />
                    )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            WordCount&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('wordCount', {
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            TableCount&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('tableCount', {
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            FigureCount&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('figureCount', {
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Editor&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('editor', {
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            ORCID&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('oRCID', {
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            RinggoldID&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('ringgoldID', {
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Telephone&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('telephone', {
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Fax&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('fax', {
                    })(<Input onChange={this.markAsDirty}/> )}
                </Form.Item> */}

                <Button disabled={disableSave} onClick={this.saveAndNext} type="primary" >Save <Icon type="right" /></Button>
            </Form>
        );
    }
}

export default Form.create()(WorkOrderForm);