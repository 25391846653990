import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader,Button,Badge,Tooltip} from 'antd';

import { humanizeDate } from '../stores/Util.js'

const columnDefs = [
    { headerName: "Journal", field: "journalShortCode"},
    { headerName: "Article Title", field: "articleTitle"},
    { headerName: "Manuscript#", field: "manuscriptNo"},
    { headerName: "Article Type", field: "articleTypeName"},
    { headerName: "Workflow", field: "workflowTriggerName"},
    { headerName: "Created At",field:"createdAt",sort: 'asc', valueFormatter: params => humanizeDate(params.data["createdAt"]), filter: false},
    { headerName: "Status", field: "status"}
];

@observer
class ArticleList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.articleSelected);
        params.api.sizeColumnsToFit();

        this.props.articleListStore.fetchArticlesInQueue();
    }

    refresh = () => {
        this.props.articleListStore.fetchArticlesInQueue();
    }

    titleHeight = () => {
        const height = window.innerHeight/1.5;
        return { height: height };
    }

    articleSelected = (params) => {
        this.props.articleStore.fetchArticle(params.data.id);
    }

  
    render() {
        const store = this.props.articleListStore;
        const articles = store.articles;

        return (
            <Fragment>
                <PageHeader
                extra = {[
                        <Button key="refresh" onClick={this.refresh} icon = "reload"/>,
                        <Tooltip key="counts" placement="bottomLeft" title={store.rowCount}>
                            <Button icon= "info-circle" count={store.rowCount} ></Button>
                        </Tooltip>
                ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(articles)}
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact 
                    columnDefs={columnDefs}
                     rowSelection={'single'} 
                     onGridReady={this.onGridReady} 
                     enableSorting={true}
                     enableFilter={true} 
                     enableColResize={true}
                     />
                </div>
            );
        }
    }

    renderRowData(articles) {
        if (this.gridApi) {
            this.gridApi.setRowData(articles);
        }
    }
}

export default ArticleList;
