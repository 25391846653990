import { decorate, observable, flow } from 'mobx';
import Api from './APIEndpoints';
import { articlesInProductionUrl } from './APIEndpoints';
import {appStore} from './AppStore';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class ArticlesInProductionStore {

  articles = [];
  listState = PENDING;
  rowCount = 0;

  constructor(props) {
    this.apiProxy = props.apiProxy;   
  }
  
  fetchArticlesInProduction = flow(function* () {
    this.listState = PENDING;

    try {
      const params = { "where": { "userId":appStore.credentials.userId.toString()}, "limit": "5" }
      const data = yield Api.articlesInProductionUrl.get(params);
      // const response = yield this.apiProxy.getAsync(articlesInProductionUrl);
      // const data = yield response.json();

      this.articles = data;
      this.rowCount = data.length;
      this.listState = DONE;
    }
    catch (e) {
      this.listState = ERROR;
    };
  });

  
}

decorate(ArticlesInProductionStore, {
  listState: observable,
  articles: observable,
  rowCount: observable
})
