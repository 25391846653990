import { decorate, observable, flow } from 'mobx';
import { selectedWorkOrderUrl,workOrderSummaryUrl,articleCreationUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class ArticleStore {

    showDrawer = false;
    state = PENDING;
    activeTab = "0";

    article = {};
    uploadSummary = {};

    drawerTabs = [
        {
            "label": "Upload Summary",
            "key": "uploadSummary",
        },
        {
            "label": "Files",
            "key" : "files"
        },
        {
            "label": "People",
            "key" : "people"
        },
        {
            "label": "Create Project",
            "key" : "additionalInfo"
        },
    ];

    constructor(props) {
        this.apiProxy = props.apiProxy;
        this.fileStore = props.fileStore;
        this.metadataStore = props.metadataStore;
        this.appStore = props.appStore;
        this.articleListStore = props.articleListStore;
        this.peopleStore = props.peopleStore;
        this.peopleListStore = props.peopleListStore;

        if(this.appStore.credentials.role==='Ops')
        {
            this.drawerTabs.pop();
            this.drawerTabs.pop();
        }
    }

    fetchArticle = flow(function* (id) {
        this.state = PENDING;
  
        try {
            const url = selectedWorkOrderUrl.replace('{id}', id)
            const response = yield this.apiProxy.getAsync(url);
            const data = yield response.json();

            this.fetchUploadedWorkOrderFiles(data.id);
            this.fetchUploadSummary(data.id);
            this.fetchPeople(data.id);

            this.article = data;
            this.state = DONE;
            this.showDrawer = true;
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        };
    });

    fetchUploadedWorkOrderFiles = (workOrderId) => {
        this.fileStore.workOrderId = workOrderId;
        this.fileStore.fetchUploadedWorkOrderFiles();
    }

    fetchPeople = (workOrderId) => {
        this.peopleListStore.workOrderId = workOrderId;
        this.peopleListStore.fetchPeople();
    }

    fetchFigureList = (workOrderId) => {
        this.figureListStore.workOrderId = workOrderId;
        this.figureListStore.fetchFigureList();
    }

    fetchUploadSummary = async(workOrderId) => {
        this.state = PENDING;

        try {
            const url = workOrderSummaryUrl.replace('{id}', workOrderId)
            const response = await this.apiProxy.getAsync(url);
            const data = await response.json();

            this.uploadSummary = data;
            this.state = DONE;
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        };
    }

    createArticle = async(values) => {
        this.state = PENDING;

        try {
            const url = articleCreationUrl.replace('{id}', this.article.id)
            const response = await this.apiProxy.asyncPost(url,values);
            const data = await response.json();

            this.state = DONE;
            this.articleListStore.fetchArticlesInQueue();

            this.showDrawer = false;
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        };
    }

    changeTab = (tabIndex) => {
        this.activeTab = tabIndex;
    }
}

decorate(ArticleStore, {
    state: observable,
    showDrawer: observable,
    activeTab: observable,

    article: observable,
    uploadSummary: observable
});