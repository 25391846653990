import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input, Tooltip, Icon,Radio } from 'antd';


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

@observer
class FigureForm extends Component {

    saveFigure = (e) => {
        e.preventDefault();

        console.log('saving')
        console.log(this.props.figureStore.articleWorkOrderId)

        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const store = this.props.figureStore;
                store.saveFigure(values);
            }
        });
    }

    render() {
        const store = this.props.figureStore;
        const figure = store.figure;

        const { getFieldDecorator } = this.props.form;

        return (
            <Form {...formItemLayout} onSubmit={this.saveFigure} >

                <Form.Item
                    label={
                        <span>
                            Figure Code &nbsp;
                            <Tooltip title="Name of the Figure">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >

                    {getFieldDecorator('figureCode', {
                        initialValue: figure.figureCode,
                        rules: [
                            {
                                required: true,
                                message: 'Figure Code is mandatory',
                                whitespace: true,
                            },
                        ],

                    })(<Input />)}
                </Form.Item>


                <Form.Item label="Colour Print">
                    {getFieldDecorator('colorPrint', {
                        initialValue: figure.colorPrint,                      
                    })(
                    <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                      )}
                </Form.Item>

                <Form.Item label="Colour Online">
                    {getFieldDecorator('colorOnline', {
                        initialValue: figure.colorOnline,                      
                    })(
                    <Radio.Group>
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                      )}
                </Form.Item>

                <Button  onClick={this.saveFigure} type="primary">Save</Button>
            
            </Form >

        );
    }
}
export default Form.create()(FigureForm);