import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Modal, Button } from 'antd';
import PeopleForm from './PeopleForm';

@observer
class PeopleModal extends Component {

    handleCancel = () => {
        this.props.peopleStore.showModal = false;
    }
    
    render() {
        const visible = this.props.peopleStore.showModal;

        return (
            <Modal title="Author Information"
                visible={visible}
                width= {600}
                onCancel={this.handleCancel}
                footer={[]}
                destroyOnClose={true}
            >
                <PeopleForm peopleStore = {this.props.peopleStore} />

            </Modal>
        )
    }
}
export default PeopleModal;