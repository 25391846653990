import { decorate, observable, flow, action} from 'mobx';
import { saveUploadedWorkOrderFilesUrl, deleteUploadedWorkOrderFilesUrl, getUploadedWorkOrderFilesUrl } from './APIEndpoints';
import { isEmpty } from './Util';
import { getTreeNodes, createTreeData } from '../workOrder/FileHelper';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class FileStore {

    drawerLoading = false;
    errorMessage = '';
    
    files = {};
    uploadedWorkOrderFiles = [];
    workOrderFileHash = {};
    fileFetchState = PENDING;

    fileSaveState = PENDING;
    filesSelected = [];

    nodes = [];
    treeData = {};

    showDuplicatesModal = false;
    duplicateIds = [];
    duplicatedFiles = [];

    workOrderId = 0;

    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    generateTreeData = () => {
        let workOrderFiles = this.uploadedWorkOrderFiles;
        this.treeData = {};

        workOrderFiles.map((fileItem) => {
            createTreeData(fileItem.filePath, this.treeData, fileItem);
        })

        const tempNodes = [];
        getTreeNodes(this.treeData, tempNodes, this)
        this.nodes = tempNodes;
    }

    fetchUploadedWorkOrderFiles = flow(function* () {
        this.fileFetchState = PENDING;
        this.workOrderFileHash = {};
        this.nodes = [];
        this.treeData = {};
        this.uploadedWorkOrderFiles = [];

        try {
            const url = getUploadedWorkOrderFilesUrl.replace('{id}', this.workOrderId);
            const response = yield this.apiProxy.getAsync(url);
            const data = yield response.json();
            this.uploadedWorkOrderFiles = data;

            this.fileFetchState = DONE;
            this.generateTreeData();
        }
        catch (e) {
            this.fileFetchState = ERROR;
            console.log(e);
        };
    });

    saveUploadedFiles = async (uploadedFile) => {

        try {
            let url = saveUploadedWorkOrderFilesUrl.replace('{id}', this.workOrderId);
            let key = uploadedFile.webkitRelativePath;
            const fileItem = this.files[key];
            let fileBody = { fileName: fileItem.name, fileSize: fileItem.size, fileType: fileItem.type, filePath: fileItem.path, articleWorkOrderId: this.workOrderId };

            const response = await this.apiProxy.asyncPost(url, fileBody);
            const data = await response.json();

            this.saveState = DONE;
            this.errorMessage = '';
            this.fetchUploadedWorkOrderFiles();

            delete (this.files[key]);

            if (isEmpty(this.files)) {
                this.setDrawerLoading(false);
            }

        }
        catch (e) {
            this.saveState = ERROR;
            this.errorMessage = e;
            console.log(e);
        }
    }

    deleteFile = async (item) => {

        try {
            const url = deleteUploadedWorkOrderFilesUrl.replace('{id}', this.workOrderId).replace('{fileId}', item.id)
            const response = await this.apiProxy.asyncDelete(url, '');
            const data = await response.json();

            this.saveState = DONE;
            this.errorMessage = '';
            this.fetchUploadedWorkOrderFiles();

            this.setDrawerLoading(false);
        }
        catch (e) {
            this.saveState = ERROR;
            this.errorMessage = e;
        }
    }

    addFiles = (file) => {
        let fileItem = { fileName: file.name, fileSize: file.size, progress: 0, status: 'normal',filePath: file.webkitRelativePath };
        let fileKey = file.webkitRelativePath;
        this.files[fileKey] = fileItem
    }
  
    setUploadedFileStatus = (uploadingFile, progress) => {
      let fileKey = uploadingFile.webkitRelativePath;
  
      const fileItem = this.files[fileKey];

      fileItem.progress = progress;
      fileItem.status = 'active';
  
      this.files[fileKey] = fileItem;
    }
  
    setCompletedFileStatus = async (uploadedFile, data) => {
      let fileKey = uploadedFile.webkitRelativePath;

      const fileItem = this.files[fileKey];
  
      fileItem.progress = 100;
      fileItem.status = 'success';
      fileItem.path = data['Key'];
      fileItem.name = uploadedFile.name;
      fileItem.type = uploadedFile.type;
      fileItem.size = uploadedFile.size;
      
      this.files[fileKey] = fileItem;
      this.saveUploadedFiles(uploadedFile);
    }

    setDrawerLoading = (loading) => {
        this.drawerLoading = loading;
    }
}

decorate(FileStore, {
  
    saveState: observable,
    save: action,
    errorMessage: observable,
  
    files: observable,
    fileSaveState: observable,

    setUploadedFileStatus:action,
    setCompletedFileStatus:action,
  
    uploadedWorkOrderFiles: observable,
    duplicatedFiles: observable,
    
    drawerLoading: observable,
  
    treeData: observable,
    nodes: observable,
    generateTreeData: action,
    setDrawerLoading: action,

    showDuplicatesModal: observable,
    duplicateIds: observable,
    workOrderFileHash: observable,

    workOrderId: observable
  
  })
  