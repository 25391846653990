import React, { Component } from 'react';

import { observer, inject } from 'mobx-react';

import Login from './LoginScreen';
import About from './About';

import WorkOrderUI from './workOrder/WorkOrderUI';
import ArticleUI from './article/ArticleUI';
import ArticleCompletedUI from './article/ArticleCompletedUI';
import ArticlesInProductionUI from './articlesInProduction/ArticlesInProductionUI';
import CompletedArticlesUI from './completedArticle/CompletedArticlesUI';

@inject("appStore")
@observer
class SelectedComponent extends Component {

    evaluateComponent() {
        const appStore = this.props.appStore;
        switch (appStore.currentComponent.key) {
            case 'WorkOrders': {
                return <WorkOrderUI />;
            }
            case 'ArticlesInQueue' :{
                return <ArticleUI />;
            }
            case 'CompletedQueue' :{
                return <ArticleCompletedUI />;
            }
            case 'ArticlesInProduction' :{
                return <ArticlesInProductionUI />;
            }
            case 'CompletedArticles' :{
                return <CompletedArticlesUI />;
            }
            case 'Login': {
                return <Login />;
            }
            case 'About': {
                return <About />;
            }
            default: {
                return <About />;
            }
        }
    }
    
    render() {
        return (this.evaluateComponent());
    }
}


export default SelectedComponent;