import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader,Button,Badge,Tooltip} from 'antd';

import { humanizeDate } from '../stores/Util.js'

const columnDefs = [
    { headerName: "Journal", field: "journalShortCode"},
    { headerName: "Article Title", field: "articleTitle"},
    { headerName: "Manuscript#", field: "manuscriptNo"},
    { headerName: "Article Type", field: "articleTypeName"},
    { headerName: "Workflow", field: "workflowTriggerName"},
    { headerName: "Created At",field:"createdAt",sort: 'asc', valueFormatter: params => humanizeDate(params.data["createdAt"]), filter: false}
];

@observer
class WorkOrderList extends Component {

    constructor(props) {
        super(props);
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.workOrderSelected);
        params.api.sizeColumnsToFit();

        this.props.workOrderListStore.fetchWorkOrders();
    }

    refresh = () => {
        this.props.workOrderListStore.fetchWorkOrders();
    }

    titleHeight = () => {
        const height = window.innerHeight/1.5;
        return { height: height };
    }

    workOrderSelected = (params) => {
       console.log(params);
        this.props.workOrderStore.fetchWorkOrder(params.data.id);
    }

    newWorkOrder = () => {
       this.props.workOrderStore.newWorkOrder(); 
    }

    render() {
        const store = this.props.workOrderListStore;
        const workOrders = store.workOrders;

        return (
            <Fragment>
                <PageHeader
                extra = {[
                        <Button key="add" onClick={this.newWorkOrder} icon = "plus"/>,
                        <Button key="refresh" onClick={this.refresh} icon = "reload"/>,

                        <Tooltip key="counts" placement="bottomLeft" title={store.rowCount}>
                            <Button  icon= "info-circle" count={store.rowCount} >
                            </Button>
                        </Tooltip>
                ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(workOrders)}
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact 
                    columnDefs={columnDefs}
                     rowSelection={'single'} 
                     onGridReady={this.onGridReady} 
                     enableSorting={true}
                     enableFilter={true} 
                     enableColResize={true}
                     />
                </div>
            );
        }
    }

    renderRowData(workOrders) {
        if (this.gridApi) {
            this.gridApi.setRowData(workOrders);
        }
    }
}

export default WorkOrderList;
