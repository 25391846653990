import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Tabs, Spin, Icon } from 'antd';

import UploadSummary from './UploadSummary';
import UploadedFiles from '../workOrder/UploadedFiles';
import AdditionalInfo from './AdditionalInfo';
import PeopleList from './PeopleList';

const { TabPane } = Tabs;

@observer
class ArticleDrawer extends Component {

    close = () => {
        const store = this.props.articleStore;
        store.showDrawer = false;
        store.activeTab = "0";
    }

    changeTab = (tabIndex) => {
        const store = this.props.articleStore;
        store.changeTab(tabIndex);
    }

    renderTab = (tabKey) => {

        switch (tabKey) {
            case "uploadSummary":
                return <UploadSummary articleStore = {this.props.articleStore} />
            
            case "files":
                return <UploadedFiles fileStore = {this.props.articleStore.fileStore}/>

            case "additionalInfo":
                return <AdditionalInfo articleStore = {this.props.articleStore} metadataStore = {this.props.articleStore.metadataStore}/>

            case "people":
                return <PeopleList peopleStore = {this.props.articleStore.peopleStore} peopleListStore = {this.props.articleStore.peopleListStore}/>
    
            default:
                return <UploadSummary articleStore = {this.props.articleStore}/>

        }
    }
  
    buildTabs = (store) => {
        return (
            <Tabs activeKey={store.activeTab} tabPosition='top' style={{ height: "100%" }} onChange={this.changeTab}>
              {[...store.drawerTabs].map((menu, index) => (
                <TabPane tab={menu.label} key={index} >
                  {this.renderTab(menu.key)}
                </TabPane>
              ))}
            </Tabs>
          )

    }

    renderTabList = () => {
        const store = this.props.articleStore;

        if (store.fileStore.drawerLoading === true) {
            return (
              <Spin tip="Loading..." indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
                {this.buildTabs(store)}
              </Spin>
            )
        }
        
        return (
           this.buildTabs(store)
        )
    }

    render() {
        const store = this.props.articleStore;
        const title = "Manuscript: "+store.article.manuscriptNo;
        return (
            <Drawer title={title} width={"45%"} closable={true} onClose={this.close} visible={store.showDrawer} destroyOnClose>
                {this.renderTabList()}
            </Drawer>
        );
    }
}

export default ArticleDrawer

