import React, { Component} from 'react';
import { Provider } from 'mobx-react';
import { Layout } from 'antd';
import TitleBar from './components/TitleBar';
import SelectedComponent from './components/SelectedComponent'
import NavigationSider from './components/NavigationSider';
import { appStore } from './components/stores/AppStore';
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import queryString from 'query-string';
const { Content } = Layout;

export default class AutoLogin extends Component {
  componentDidMount()
  {
    this.AutoLoginCaller();
  }
  AutoLoginCaller = async() =>
  {
   const queryParams = queryString.parse(location.search);
   console.log(queryParams);
   await appStore.autoAuthenticate(queryParams);
   window.location.href = '/';
  }

  render() {
    return (
      <div>
        <Provider appStore={appStore}>
          <Layout style={{minHeight: '100vh'}} >
            <NavigationSider />
            <Layout>
            <TitleBar></TitleBar>
              <Content>
                <SelectedComponent />
              </Content>
            </Layout>
          </Layout>
        </Provider>
      </div>
     );
  }
}