import React, { Component } from 'react';
import { inject } from 'mobx-react';

import WorkOrderList from './WorkOrderList';
import WorkOrderDrawer from './WorkOrderDrawer';

import WorkOrderListStore from '../stores/WorkOrderListStore';
import WorkOrderStore from '../stores/WorkOrderStore';
import FileStore from '../stores/FileStore';
import PeopleListStore from '../stores/PeopleListStore';
import PeopleStore from '../stores/PeopleStore';
import FigureListStore from '../stores/FigureListStore';
import FigureStore from '../stores/FigureStore';
import MetadataStore from '../stores/MetadataStore';

@inject("appStore")
class WorkOrderUI extends Component {

    constructor(props) {
        super(props);
        this.workOrderListStore = new WorkOrderListStore({ apiProxy: props.appStore.apiProxy });
    }

    render() {
        const fileStore = new FileStore({ apiProxy: this.props.appStore.apiProxy });

        const peopleListStore = new PeopleListStore({ apiProxy: this.props.appStore.apiProxy });

        const peopleStore = new PeopleStore({
            apiProxy: this.props.appStore.apiProxy,
            peopleListStore: peopleListStore
        });

        const figureListStore = new FigureListStore({ apiProxy: this.props.appStore.apiProxy });

        const figureStore = new FigureStore({
            apiProxy: this.props.appStore.apiProxy,
            figureListStore: figureListStore
        });

        const metadataStore = new MetadataStore({ apiProxy: this.props.appStore.apiProxy })

        const workOrderStore = new WorkOrderStore({
            apiProxy: this.props.appStore.apiProxy,
            listStore: this.workOrderListStore,
            fileStore: fileStore,
            peopleListStore: peopleListStore,
            peopleStore: peopleStore,
            figureListStore: figureListStore,
            figureStore: figureStore,
            metadataStore: metadataStore
        });

        return (
            <div>
                <WorkOrderList workOrderListStore={this.workOrderListStore} workOrderStore={workOrderStore} />
                <WorkOrderDrawer workOrderStore={workOrderStore} />
            </div>
        )
    }

}

export default WorkOrderUI;

