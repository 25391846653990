import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'antd/dist/antd.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {LicenseManager} from 'ag-grid-enterprise';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import AutoLogin from './AutoLogin';
 
const env = process.env.REACT_APP_ENV;
if(env === "prod"){
    ReactGA.initialize('UA-152875801-4');
}

LicenseManager.setLicenseKey("Newgen_Knowledge_Works_Ltd_MultiApp_1Devs5_April_2019__MTU1NDQxODgwMDAwMA==f0636d4a0070aed2fa512c5159add138");

//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(<Router>
    <div>
      <Route exact path='/' component={App} />
      <Route exact path='/autologin' component={AutoLogin} />
    </div>
     </Router>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
