import AWS from 'aws-sdk';
import s3Config from './stores/environments/config'

const credentials = new AWS.CognitoIdentityCredentials({IdentityPoolId:s3Config.AWS_IDENTITY_POOL_ID});

AWS.config.update({
        region:s3Config.AWS_REGION,
        credentials:credentials
    }
);

const s3 = new AWS.S3({
        params: {Bucket: s3Config.AWS_BUCKET }
});

AWS.config.credentials.refresh();

export default class s3Util {

    static downloadFile = (filePath) => {

        const s3Params = {
            Bucket: s3Config.AWS_BUCKET,
            Key: filePath,
            Expires: s3Config.EXPIRE_TIME
        };

        s3.getSignedUrl('getObject', s3Params, (err, data) => {
            if (!err) {
                window.location.href = data;
            } else {
                console.log(err);
            }
        });
    }

    static uploadFiles = (files, store) => {
        for (var index = 0; index < files.length; index++) {
            this._uploadFile(files[index], store);
        }
    };

    static deleteFiles = (files, store) => {
        for (var index = 0; index < files.length; index++) {
            this._deleteFile(files[index], store);
        }
    };

    static _uploadFile = (file, store, uniqueKey) => {
        let body = "";
        if (file.originFileObj === undefined) {
            body = file;
        } else {
            delete(file.originFileObj['uid']);
            body = file.originFileObj;
        }
        let params = {
            Key: s3Config.AWS_BUCKET_BASE_PATH + '/' + uniqueKey + '/' + file.webkitRelativePath,
            Body: body,
            Bucket: s3Config.AWS_BUCKET
        };

        let request = s3.upload(params);

        request.on('httpUploadProgress', function (evt) {
            store.setUploadedFileStatus(file, parseInt(evt.loaded * 100 / evt.total));
        });
        request.send(function (evt, data) {
            if (data === undefined) {
                return;
            }
            store.setCompletedFileStatus(file, data);
        });
    }

    static _deleteFile = (item, store, filePath) => {

        let params = {
            Bucket: s3Config.AWS_BUCKET,
            Key: filePath
        };

        s3.deleteObject(params, function (err, data) {
            if (!err) {
                store.deleteFile(item);
            } else {
                console.log(err)
            }
        });

    }

    static _emptyS3Directory = async (dir) => {

        const listParams = {
            Bucket: s3Config.AWS_BUCKET,
            Prefix: dir
        };

        const listedObjects = await s3.listObjectsV2(listParams).promise();

        if (listedObjects.Contents.length === 0) return;

        const deleteParams = {
            Bucket: s3Config.AWS_BUCKET,
            Delete: {
                Objects: []
            }
        };

        listedObjects.Contents.forEach(({
            Key
        }) => {
            deleteParams.Delete.Objects.push({
                Key
            });
        });

        await s3.deleteObjects(deleteParams).promise();

        if (listedObjects.IsTruncated) await this._emptyS3Directory(dir);
    }

}