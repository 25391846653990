import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Table,} from 'antd';

import DuplicateFilesModal from './DuplicateFilesModal';

const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        width: window.innerWidth / 12,
        key: 'name',

    },
    {
        title: 'Size',
        dataIndex: 'fileSize',
        width: window.innerWidth / 25,
        key: 'fileSize',
    },
    {
        title: 'Uploaded By',
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: window.innerWidth / 25,
    },
    {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        width: window.innerWidth / 25,
    },
];

const pagination = {pagination: {pageSize: 5}};

@observer
class UploadedFiles extends Component {

    render() {
        const store = this.props.fileStore;
        return (
            <div>
                <DuplicateFilesModal fileStore = {this.props.fileStore}/>
                <Table {...pagination} columns={columns} dataSource={store.nodes} size="middle" defaultExpandAllRows={true} />
            </div>
        );
    }
}

export default UploadedFiles;
