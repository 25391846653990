import React, { Component } from 'react';
import { inject } from 'mobx-react';

import ArticleList from './ArticleList';
import ArticleDrawer from './ArticleDrawer';

import ArticleListStore from '../stores/ArticleListStore';
import ArticleStore from '../stores/ArticleStore';
import FileStore from '../stores/FileStore';
import MetadataStore from '../stores/MetadataStore';
import PeopleStore from '../stores/PeopleStore';
import PeopleListStore from '../stores/PeopleListStore';

@inject("appStore")
class ArticleUI extends Component {

    constructor(props) {
        super(props);
        this.articleListStore = new ArticleListStore({ apiProxy: props.appStore.apiProxy });
    }

    render() {
        const fileStore = new FileStore({ apiProxy: this.props.appStore.apiProxy });

        const peopleListStore = new PeopleListStore({ apiProxy: this.props.appStore.apiProxy });
        const peopleStore = new PeopleStore({
            apiProxy: this.props.appStore.apiProxy,
            peopleListStore: peopleListStore
        });

        const metadataStore = new MetadataStore({ apiProxy: this.props.appStore.apiProxy });
        
        const articleStore = new ArticleStore({ 
            apiProxy: this.props.appStore.apiProxy,
            fileStore: fileStore,
            peopleStore: peopleStore,
            peopleListStore: peopleListStore,
            metadataStore: metadataStore, 
            appStore: this.props.appStore,
            articleListStore: this.articleListStore });

        return (
            <div>
                <ArticleList articleListStore={this.articleListStore} articleStore={articleStore}/>
                <ArticleDrawer articleStore={articleStore}/>
            </div>
        )
    }

}
export default ArticleUI;
