import React from 'react';

const NewgenLogo = (props) => {
  const small = 'https://pubkit.newgen.co/assets/newgen_favicon-a5862f6f5a42b784ac7612e26961341040ee668c8b8412b6ef3d4b8c4612fc57.ico';
  const large = 'https://pubkit-developers-open.s3.amazonaws.com/static/images/pubkit-logo.png';
  const logoUrl = props.small ? small : large;
  if(props.small){
    return <img src={logoUrl} />;
  }
  else
  {
    return <img style={{width: "140px"}} src={logoUrl} />;
  }
 
};

export default NewgenLogo;
