import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Button, Icon} from 'antd';

const content = {
    overflow: 'auto',
}

@observer
class UploadSummary extends Component {
    constructor(props) {
        super(props);
    }

    next = () => {
        this.props.articleStore.changeTab('1');
    }
   
    render() {

        const uploadSummary = this.props.articleStore.uploadSummary;
  
        return (
            <div>
                <div style={content} dangerouslySetInnerHTML={{ __html: uploadSummary.previewContent }}></div>
                <Button onClick={this.next} type="primary">Next <Icon type="right" /></Button>
            </div>
        )

    }
}

export default UploadSummary;