import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader,Button,Badge,Tooltip} from 'antd';
import { humanizeDate } from '../stores/Util.js'

var s3awsConfig = "https://newgen-ae-dev.s3.amazonaws.com/";
if(window.location.host == 'bookin.newgen.co')
{
    s3awsConfig = "https://newgen-pk-prod.s3.amazonaws.com/";
}

const columnDefs = [
    { headerName: "Journal", field: "journalShortCode"},
    { headerName: "Article Title", field: "articleTitle"},
    { headerName: "Manuscript#", field: "manuscriptNo"},
    { headerName: "Article Type", field: "articleTypeName"},
    { headerName: "Workflow", field: "workflowTriggerName"},
    { headerName: "Created At",field:"createdAt",sort: 'asc', valueFormatter: params => humanizeDate(params.data["createdAt"]), filter: false},
    { headerName: "Status", field: "status"},
    {
        headerName: "Download",
        children: [
    {headerName: 'Zip File', cellRendererFramework: function(params) {
        return <Button icon="download" onClick={()=> window.open(s3awsConfig+"uploads/bookin_manuscripts/"+params.data["id"]+"-"+params.data["manuscriptNo"]+".zip", "_blank")}></Button> 
      }
    },                                                              
    {headerName: 'Xml File', cellRendererFramework: function(params) {
        return <Button  icon="download" onClick={()=> window.open(s3awsConfig+"uploads/bookin_manuscripts/"+params.data["id"]+"-"+params.data["manuscriptNo"]+".xml", "_blank")}></Button> 
      }
    },
    ]},
];

@observer
class ArticleCompletedList extends Component {

    constructor(props) {
        super(props);
    }
 
    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        //this.gridApi.addEventListener('rowClicked', this.articleSelected);
        params.api.sizeColumnsToFit();

        this.props.articleCompletedListStore.fetchArticlesInQueue();
    }

    refresh = () => {
        this.props.articleCompletedListStore.fetchArticlesInQueue();
    }

    titleHeight = () => {
        const height = window.innerHeight/1.3;
        return { height: height };
    }

    articleSelected = (params) => {
        this.props.articleStore.fetchArticle(params.data.id);
    }

    renderFooter = () => {
        return (
            <div >
            <center><h4> *This page list the last 200 records submitted to FTP. </h4></center>
            </div>
        )
    }
  
    render() {
        const store = this.props.articleCompletedListStore;
        const articles = store.articles;

        return (
            <Fragment>
                <PageHeader
                extra = {[
                        <Button key="refresh" onClick={this.refresh} icon = "reload"/>,
                        <Tooltip key="counts" placement="bottomLeft" title={store.rowCount}>
                            <Button icon= "info-circle" count={store.rowCount} ></Button>
                        </Tooltip>
                ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(articles)}
                {this.renderFooter()}
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact 
                    columnDefs={columnDefs}
                     rowSelection={'single'} 
                     onGridReady={this.onGridReady} 
                     enableSorting={true}
                     enableFilter={true} 
                     enableColResize={true}
                     />
                </div>
            );
        }
    }

    renderRowData(articles) {
        if (this.gridApi) {
            this.gridApi.setRowData(articles);
        }
    }
}

export default ArticleCompletedList;
