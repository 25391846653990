import Http from './Http.js';
import ApiUtil from './ApiUtil';
import {appStore} from './AppStore';
let backendHost = 'https://pubkit.newgen.co/submissions/api/ajhp/';
let xLoginUrl = 'https://pubkit.newgen.co/auth';
// let backendHost = 'https://pubkittest.newgen.co/submissions/api/ajhp/';
// let xLoginUrl = 'https://pubkittest.newgen.co/auth';

const hostname = window && window.location && window.location.hostname;

if (hostname === 'localhost') {
  xLoginUrl = 'http://localhost:3000/auth';
    //  backendHost = 'https://pubkit.newgen.co/submissions/api/ajhp/';
    //  xLoginUrl = 'https://pubkit.newgen.co/auth';
    backendHost = 'https://pubkittest.newgen.co/submissions/api/ajhp/';
    xLoginUrl = 'https://pubkittest.newgen.co/auth';
}

if (hostname === 'bookintest.web.app') {
  backendHost = 'https://pubkittest.newgen.co/submissions/api/ajhp/';
  xLoginUrl = 'https://pubkittest.newgen.co/auth';
}


let API_ROOT = ApiUtil.isDevelopment()
  ? 'http://localhost:3333/reportsmicro/'
  : 'https://pubsub.newgen.co/reportsmicro/';

if (window.location.origin.indexOf("bookintest.web.app") != -1) {
  API_ROOT = 'https://pubsubtest.newgen.co/reportsmicro/';
}

const parseJson = res => res.json();
const defaultConfig = () => ({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
   //'access-control-allow-origin': '*',
    email: appStore.credentials.email,
    token: appStore.credentials.token,
    Origin: 'https://pubkit.newgen.co',
  },
});

//export const genericApi = new Http(API_ROOT + "/approvedqueries/fuzzyid/", defaultConfig);
export const genericApi = new Http(API_ROOT + "reports/approved_queries/", defaultConfig);

export const journalsUrl = {
  get: params => genericApi.post('bookin_user_journals', params).then(parseJson),
};
//export const journalsUrl = backendHost+"ajhp/journals";
export const journalArticleTypesUrl = backendHost + "journals/{id}/articleTypes";
export const workflowUrl = backendHost + "journals/{id}/articleTypes/{articleTypeId}/workflows";

export const unreleasedWorkOrderUrl = {
  get: params => genericApi.post('bookin_unreleased_article_work_orders', params).then(parseJson),
};
//export const unreleasedWorkOrderUrl = backendHost + "ajhp/articles/workorders/unreleased";

export const queuedWorkOrderUrl = {
  get: params => genericApi.post('bookin_queued_article_work_orders', params).then(parseJson),
};

//export const queuedWorkOrderUrl = backendHost + "ajhp/articles/workorders/queued";

export const completedWorkOrderUrl = {
  get: params => genericApi.post('bookin_completed_queued_article_work_orders', params).then(parseJson),
};

//export const completedWorkOrderUrl = backendHost + "ajhp/articles/workorders/completedQueue";
export const selectedWorkOrderUrl = backendHost + "articles/workorders/{id}";
export const saveWorkOrderUrl = backendHost + "articles/workorders/save";
export const finalizeWorkOrderUrl = backendHost + "articles/workorders/{id}/release";
export const isManuscriptAvailable = backendHost + "articles/workorders/isManuscriptAvailable/{manuscriptNo}";

export const getUploadedWorkOrderFilesUrl = backendHost + "articles/workorders/{id}/files";
export const saveUploadedWorkOrderFilesUrl = backendHost + "articles/workorders/{id}/files";
export const deleteUploadedWorkOrderFilesUrl = backendHost + "articles/workorders/{id}/files/{fileId}";

export const workOrderPeopleUrl = backendHost + "articles/workorders/{id}/people";
export const workOrderPersonUrl = backendHost + "articles/workorders/{id}/people/{peopleId}";

export const workOrderFigureListUrl = backendHost + "articles/workorders/{id}/figures";
export const workOrderFigureUrl = backendHost + "articles/workorders/{id}/figure/{figureId}";

export const metadataUrl = backendHost + "articles/workorders/{id}/metadata";

export const workOrderSummaryUrl = backendHost + "articles/workorders/{id}/summary";
export const articleCreationUrl = backendHost + "articles/workorders/{id}/create";

export const articlesInProductionUrl = {
  get: params => genericApi.post('bookin_articles_in_production', params).then(parseJson),
};

//export const articlesInProductionUrl = backendHost + "ajhp/articles/";

export const articlesInCompletedArticlesUrl = {
  get: params => genericApi.post('bookin_articles_in_completedArticles', params).then(parseJson),
};

//export const articlesInCompletedArticlesUrl = backendHost + "ajhp/articles/completedArticles";

export const autoLoginUrl = backendHost + 'journals/autologin';

export const loginUrl = xLoginUrl;

const Api = {

  journalsUrl,
  unreleasedWorkOrderUrl,
  queuedWorkOrderUrl,
  completedWorkOrderUrl,
  articlesInCompletedArticlesUrl,
  articlesInProductionUrl

};

export default Api;