import moment from 'moment';
import { message } from 'antd';

export const humaniseDate = (raw, format = 'DD-MMM-YYYY HH:mm') => {
  if (!raw) return null;
  return moment.utc(raw).local().format(format);
};

export const formatSqlDate = (date) => {
  if (!date) return null;
  return moment(date).format('YYYY-MM-DD');
};

export const addedHoursMinsToDate = (date) => {
  if (!date) return null;
  return moment(date).add(5,'hours').add(30,'minutes').format('DD-MMM-YYYY');
};

export const SqlDate = (date) => {
  if (!date) return null;
  return moment(date).format('DD-MMM-YYYY');
};

export const isNull = (value) => {
  if (typeof value === 'string') {
    return value.length == 0;
  }
  return value == null || value == undefined;
};
//export const isDevelopment = () => process.env.NODE_ENV === 'development';
export const isDevelopment = () => false;

export const toMap = (list, key) => {
  const map = {};
  list.forEach((item) => {
    map[item[key]] = item;
  });
  return map;
};

export const parseBoolean = (val) => {
  if (!val) return false;
  return `${val}`.toLowerCase() == 'yes';
};

export const showErrMsg = msg => message.error(msg);

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const intersection = (a1, a2) => {
  if (!a1 || !a2) return false;
  return a1.filter(v => a2.includes(v));
};

export const toUrlParams = params => new URLSearchParams(params).toString();

const Util = {
  humaniseDate,
  formatSqlDate,
  addedHoursMinsToDate,
  SqlDate,
  isNull,
  isDevelopment,
  toMap,
  parseBoolean,
  showErrMsg,
  isObject,
  mergeDeep,
  intersection,
  toUrlParams,
};

export default Util;
