import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input, Tooltip, Icon,Switch,message } from 'antd';
import {isEmpty} from '../stores/Util.js'

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

@observer
class PeopleForm extends Component {

    validateCorresEmail = (values) => {
        if(values.corres){
            return !isEmpty(values.email);
        }
        return true;
    }

    savePerson = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err && this.validateCorresEmail(values)) {
                const store = this.props.peopleStore;
                store.savePerson(values);
            }
            else{                
                message.error("Please provide email for the corresponding author")
            }
        });
    }

    handleCorres = (choice) => {
        const store = this.props.peopleStore;
        store.person.corres = choice;
    }

    handleLeadAuthor = (choice) => {
        const store = this.props.peopleStore;
        store.person.leadAuthor = choice;
    }

    render() {
        const store = this.props.peopleStore;
        const person = store.person;

        const { getFieldDecorator } = this.props.form;

        return (
            <Form {...formItemLayout} onSubmit={this.savePerson} >

                <Form.Item
                    label={
                        <span>
                            Salutation/Title &nbsp;
                            <Tooltip title="The salutation like:Prof.,Dr., of the Author of the Article">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >

                    {getFieldDecorator('salutation', {
                        initialValue: person.salutation,
                        rules: [
                            {
                                required: true,
                                message: 'The salutation of the Author is mandatory',
                                whitespace: true,
                            },
                        ],

                    })(<Input />)}
                </Form.Item>


                <Form.Item
                    label={
                        <span>
                            First Name&nbsp;
                        </span>
                    }
                >

                    {getFieldDecorator('firstName', {
                        initialValue: person.firstName,
                        rules: [
                            {
                                required: true,
                                message: 'The first name of the Author is mandatory',
                                whitespace: true,
                            },
                        ],

                    })(<Input />)}
                </Form.Item>



                <Form.Item
                    label={
                        <span>
                            Middle Name&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('middleName', {
                        initialValue: person.middleName
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Last Name&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('lastName', {
                        initialValue: person.lastName,
                        rules: [
                            {
                                required: true,
                                message: 'Please provide the last name of the author',
                                whitespace: true,
                            },
                        ],
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Corresponding Author? &nbsp;
                            <Tooltip title="Is this the corresponding Author">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('corres', {
                        initialValue: person.corres,
                    })(<Switch onChange={this.handleCorres} checked= {store.person.corres}/>)}

                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Lead Author? &nbsp;
                            <Tooltip title="Is this the Lead Author">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('leadAuthor', {
                        initialValue: person.leadAuthor,
                    })(<Switch onChange={this.handleLeadAuthor} checked= {store.person.leadAuthor}/>)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Author E-mail&nbsp;
                        </span>
                    }
                >

                    {getFieldDecorator('email', {
                        initialValue: person.email,
                        rules: [
                            {
                                type: 'email',
                                required: false,
                                message: 'Please enter a valid email',
                                whitespace: true,
                            },
                        ],

                    })(<Input />)}
                </Form.Item>
                <Form.Item
                    label={
                        <span>
                            Postal Code&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('postCode', {
                        initialValue: person.postCode
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Country&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('country', {
                        initialValue: person.country
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            County&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('county', {
                        initialValue: person.county
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Town&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('town', {
                        initialValue: person.town
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Address&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('address', {
                        initialValue: person.address
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Department&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('department', {
                        initialValue: person.department
                    })(<Input />)}
                </Form.Item>

                <Form.Item
                    label={
                        <span>
                            Institution&nbsp;
                        </span>
                    }
                >
                    {getFieldDecorator('institution', {
                        initialValue: person.institution
                    })(<Input />)}
                </Form.Item>

                <Button  onClick={this.savePerson} type="primary">Save</Button>
            
            </Form >

        );
    }
}

export default Form.create()(PeopleForm);