import { decorate, observable, flow } from 'mobx';

import { workOrderPeopleUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

export default class PeopleListStore {

    workOrderId = -1;

    people = [];
    listState = PENDING;
    rowCount = 0;
       
    constructor(props) {
        this.apiProxy = props.apiProxy;
    }

    fetchPeople = flow(function* () {
        this.listState = PENDING;
        try {

            const url = workOrderPeopleUrl.replace('{id}',this.workOrderId);
            const response = yield this.apiProxy.getAsync(url);
            const data = yield response.json();

            this.people = data;
            this.rowCount = data.length;
            this.listState = DONE;

        }
        catch (e) {
            this.listState = ERROR;
        };
    });
}

decorate(PeopleListStore, {
    listState: observable,
    people: observable,
    rowCount: observable,
    workOrderId: observable
})