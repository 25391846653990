import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Progress, Typography, Icon, Upload,Button,message } from 'antd';
import UploadedFiles from './UploadedFiles'

import s3Util from '../../components/s3Util.js';
import { generateUniqueKey } from '../stores/Util';

const { Dragger } = Upload;
const { Text } = Typography;

@observer
class WorkOrderFiles extends Component {

    next = () => {
        this.props.workOrderStore.changeTab('2');
    }

    showUploadHistory() {
        const store = this.props.workOrderStore.fileStore;
        const aList = Object.values(store.files);
        return (
            <div style={{ width: "95%" }}>
                {aList.map(item => (
                    <div key={`${item.filePath}/${item.fileName}`}>
                        <Progress percent={item.progress} status={item.status} />
                        <Text>{item.fileName}</Text>
                    </div>
                ))}
            </div>
        );
    }
    
    dragAndDropArea = () => {
        const store = this.props.workOrderStore.fileStore;
        const uniqueKey = generateUniqueKey();
        const draggerProps = {
            directory: true,
            showUploadList: true,
            beforeUpload(file) {
                const letters = /^[A-Za-z0-9.-_-]+$/;
                if(file.webkitRelativePath.match(letters))
                {
                store.addFiles(file);
                store.drawerLoading = true;
                s3Util._uploadFile(file, store, uniqueKey);
                }
                else
                {
                message.error('File name is not vaild. The file name should contain only [A-Za-z0-9.-_-]');
                 return false;
                }
            }
        }

        return(
            <Dragger {...draggerProps}>
                <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                </p>
                <p className="ant-upload-text">Drag and drop a file here or Click to upload a folder</p>
            </Dragger>
        )
    }

    render() {
        return (
            <div>
                {this.dragAndDropArea()}
                <br></br>
                <UploadedFiles fileStore = {this.props.workOrderStore.fileStore}/>
                <br></br>
                {this.showUploadHistory()}

                <Button onClick={this.next} type="primary" >Next <Icon type="right" /></Button>
                
            </div>
        );
    }

}

export default WorkOrderFiles;
