import React, { Component } from 'react';
import { inject } from 'mobx-react';

import ArticlesInProductionStore from '../stores/ArticlesInProductionStore';
import ArticleInProductionList from './ArticleInProductionList';

@inject("appStore")
class ArticleInProductionUI extends Component {

    constructor(props) {
        super(props);
        this.articlesInProductionStore = new ArticlesInProductionStore({ apiProxy: props.appStore.apiProxy , appStore: this.props.appStore,});
    }

    render() {
        return (
            <div>
                <ArticleInProductionList articlesInProductionStore={this.articlesInProductionStore}/>
            </div>
        )
    }

}
export default ArticleInProductionUI;
