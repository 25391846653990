import React, { Component } from 'react';
import { inject,observer } from 'mobx-react';
import AccountMenu from './AccountMenu';
import { Layout, Typography, Dropdown, Button } from 'antd';
import { relative } from 'path';

const { Header } = Layout;
const { Title } = Typography;

@inject("appStore")
@observer
export default class TitleBar extends Component {
    renderMenu() {
        const appStore = this.props.appStore;
        if (!appStore.isLoggedIn()) return null;

        return (
            <Dropdown overlay={<AccountMenu />} trigger={['click']} style={{ marginRight: '-2%' }}>
                <Button icon={'user'} >
                    {appStore.credentials.username}
                </Button>
            </Dropdown>
        )
    }

    renderTitleContent(){

        const appStore = this.props.appStore;
        if (!appStore.isLoggedIn()) {
            return (
                <div></div>
            );
        }

        return appStore.currentComponent.label;
       
    }

    bookinTitle = () => {
        return (

            "Bookin"

        )
    }

    render() {
        const appStore = this.props.appStore;

        return (
            <Header style={{ backgroundColor: '#001529', paddingTop: 10, height: 70 }} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <Title level = {3} style={{color: "#FBFBFB"}}>
                        {this.renderTitleContent()}
                    </Title>
                    <Title level = {3} style={{color: "#FBFBFB", marginTop: "2px"}}>
                        Journal-Bookin
                    </Title>
                    {this.renderMenu()}
                </div>
            </Header>
        )
    }
}