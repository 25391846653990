import React from 'react';
import s3Util from '../../components/s3Util.js';
import { Icon, Divider, Modal,Popover } from 'antd';
import { isEmpty, humanizeFileSize } from '../stores/Util.js';

const { confirm } = Modal;
const  metaInfoHook = "<>metaInfo";

function deleteFile(item, store) {
    if (!isEmpty(item.id)) {
        let filePath = `${item.filePath}/${item.name}`;
        store.drawerLoading = true;
        s3Util._deleteFile(item, store, filePath);
    }
}

function showDeleteConfirm(item, store) {

    confirm({
        title: 'Are you sure you want to delete this File?',
        content: `${item.name}`,
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
            deleteFile(item, store);
        },
        onCancel() {
        },
    });
}

function downloadFile(item) {
    let filePath = `${item.filePath}/${item.name}`;
    s3Util.downloadFile(filePath);
}

function createUploadedWorkOrderFilesHash(store) {

    let workOrderFileHash = {};
    
    store.uploadedWorkOrderFiles.map(item => {
        workOrderFileHash[item.id] = item;
    })
    store.workOrderFileHash = workOrderFileHash;
}

function showDuplicates(item, store) {

    store.duplicateIds = item.duplicateIds

    if (isEmpty(store.workOrderFileHash)) {
        createUploadedWorkOrderFilesHash(store);
    }

    let files = store.workOrderFileHash

    let duplicatedFiles = [];

    item.duplicateIds.map(id => {
        duplicatedFiles.push(files[id])
    })

    duplicatedFiles.push(files[item.id])

    store.duplicatedFiles = [];
    store.duplicatedFiles = duplicatedFiles;

    store.showDuplicatesModal = true;

}

export function renderActionItems(item, store) {

    if (!isEmpty(item.duplicateIds)) {

        return (
            <Popover content={<p>{item.name}</p>} title="Duplicate Files" trigger="hover">
                <Icon type="info-circle" onClick={() => { showDuplicates(item, store) }} key={`${item.id}Info`} />
            </Popover>
        )
    }

    return (
        <div>
            <Icon type="download" onClick={() => { downloadFile(item) }} key={`${item.id}Download`} />
            <Divider type="vertical" key={`${item.id}Divider`} />
            <Icon type="delete" onClick={() => { showDeleteConfirm(item, store) }} key={`${item.id}Delete`}/>
        </div>
    )
}

/*
    Will create a formatted data that will be fed to the file repo
*/
export function getTreeNodes(data, nodes, store) {

    let filteredData = Object.keys(data).filter(filterFileDetails,metaInfoHook);

    filteredData.map(key => {
        let metaDetails = data[key][key + metaInfoHook];

        let nodeDetails = {};
        nodeDetails["name"] = key;
        nodeDetails["key"] = metaDetails['filePath'] + key;
        nodeDetails["filePath"] = metaDetails['filePath'];
        nodeDetails["createdBy"] = metaDetails['createdBy'];
        nodeDetails["fileSize"] = humanizeFileSize(metaDetails['fileSize']);

        if (isLeafNode(data, key)) {
            nodeDetails["id"] = metaDetails['id'];
            nodeDetails["duplicateIds"] = metaDetails['duplicateIds'];
            nodeDetails["actions"] = renderActionItems(nodeDetails, store);
            nodes.push(nodeDetails);
        }

        else {
            const nodeChildren = [];
            getTreeNodes(data[key], nodeChildren, store);
            nodeDetails["children"] = nodeChildren;
            nodes.push(nodeDetails);
        }
    })
}

/*
    Will create a structured treeData from uploaded files 

    For example:
    
    for the filePath  'uploads/files/c3dbfdcb82571-1be3913b81adf/upload_titles/ArtWork/figure_1.jpg' the resulting treeData will be
    
    treeData = {
                upload_titles:{
                    ArtWork:{
                        figure_1.jpg:{
                            figure_1.jpg<>metaInfo:{ filePath: path, fileSize: size, createdBy: createdByName, id: id, actualFilePath: actualFilePath}
                        }
                        ArtWork<>metaInfo:{
                            {filePath: /upload_titles, actualFilePath: uploads/files/c3dbfdcb82571-1be3913b81adf/upload_titles/ArtWork/figure_1.jpg};
                        }
                    }
                    upload_titles<>metaInfo:{
                            {filePath: '', actualFilePath: uploads/files/c3dbfdcb82571-1be3913b81adf/upload_titles/ArtWork/figure_1.jpg};
                        }
                }
            }

    the First 3 parts of the file path will be ignored, i.e., upto to unique hexkey.
*/

export function createTreeData(filePath, tree, item) {

    let parts = filePath.split('/').splice(3);
    parts.map((part, index) => {

        let fileMetaName = part + metaInfoHook

        if (isFileDuplicated(tree, part, fileMetaName)) {
            addDuplicateIds(tree, part, fileMetaName, item);
        }

        else if (isEmpty(tree[part])) {
            createNode(filePath, index, parts, item, fileMetaName, tree, part);
        }

        tree = tree[part];
    })

}

function createNode(filePath, index, parts, item, fileMetaName, tree, part) {
    let currPath = filePath.split('/', (3 + index)).join("/");
    let metaDetailsHash = {};
    let metaDetails = { "filePath": currPath, "actualFilePath": filePath };

    if ((parts.length - 1) === index) {
        metaDetails = { "filePath": currPath, "fileSize": item.fileSize, "createdBy": item.fullName, "id": item.id, "actualFilePath": filePath };
    }

    metaDetailsHash[fileMetaName] = metaDetails;
    tree[part] = metaDetailsHash;
}

function addDuplicateIds(tree, part, fileMetaName, item) {
    let metaDetails = tree[part][fileMetaName];
    if (isEmpty(metaDetails["duplicateIds"])) {
        metaDetails["duplicateIds"] = [];
    }
    metaDetails["duplicateIds"].push(item.id);
}

function isFileDuplicated(tree, part, fileMetaName) {
    return ( !isEmpty(tree[part]) && !isEmpty(tree[part][fileMetaName]) && Object.keys(tree[part]).length === 1 );
}

function filterFileDetails(item) {

    if (!item.endsWith(metaInfoHook)) {
        return item;
    }
}

function isLeafNode(data, key) {
    let nodeDetails = data[key];
    return isEmpty(nodeDetails) || (Object.keys(nodeDetails).length === 1 && Object.keys(nodeDetails)[0] === key + metaInfoHook);
}
