import { decorate, observable, flow, action } from 'mobx';

import { workOrderPeopleUrl, workOrderPersonUrl } from './APIEndpoints';

const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

const freshPerson = {
    id: 0,
    articleWorkOrderId: -1,
    type: 'Author',
    salutation: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    corres: true,
    leadAuthor: false,
}

export default class PeopleStore {

    person = {};
    showModal = false;

    constructor(props) {
        this.apiProxy = props.apiProxy;

        this.peopleListStore = props.peopleListStore;
    }

    newPerson = () => {
        this.person = freshPerson;
        this.person.articleWorkOrderId = this.peopleListStore.workOrderId;
        this.state = DONE;
        this.showModal = true;
    }

    fetchPerson = async (articleWorkOrderId, articleWorkOrderPeopleId) => {

        this.state = PENDING;
        try {

            const url = workOrderPersonUrl.replace('{id}', articleWorkOrderId).replace('{peopleId}', articleWorkOrderPeopleId);
            const response = await this.apiProxy.getAsync(url);
            const data = await response.json();

            this.person = data;
            this.state = DONE;
            this.showModal = true;
        }
        catch (e) {
            this.state = ERROR;
        }
    }

    savePerson = async (values) => {

        this.state = PENDING;

        try {

            const url = workOrderPeopleUrl.replace('{id}', this.person.articleWorkOrderId);

            const response = await this.apiProxy.asyncPost(url, { id: this.person.id, type: this.person.type, ...values });
            const data = await response.json();

            this.person.id = data.id;
            this.peopleListStore.fetchPeople();
            this.state = DONE;
            this.showModal = false;
        }
        catch (e) {
            this.state = ERROR;
        }
    }

    deletePerson = async () => {

        this.state = PENDING;

        try {

            const url = workOrderPersonUrl.replace('{id}', this.person.articleWorkOrderId).replace('{peopleId}', this.person.id);

            const response = await this.apiProxy.asyncDelete(url);
            const data = await response.json();

            this.person.id = data.id;
            this.peopleListStore.fetchPeople();
            this.state = DONE;
            this.showModal = false;
        }
        catch (e) {
            this.state = ERROR;
            console.error(e);
        }
    }
}

decorate(PeopleStore, {
    state: observable,
    person: observable,
    showModal: observable,

    newPerson: action,
    savePerson: action,
    fetchPerson: action
})