import React, { Component } from 'react';
import { inject } from 'mobx-react';

import CompletedArticlesStore from '../stores/CompletedArticlesStore';
import CompletedArticlesList from './CompletedArticlesList';

@inject("appStore")
class CompletedArticlesUI extends Component {

    constructor(props) {
        super(props);
        this.completedArticlesStore = new CompletedArticlesStore({ apiProxy: props.appStore.apiProxy , appStore: this.props.appStore,});
    }

    render() {
        return (
            <div>
                <CompletedArticlesList completedArticlesStore={this.completedArticlesStore}/>
            </div>
        )
    }

}
export default CompletedArticlesUI;
