import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Drawer, Tabs, Spin, Icon,message } from 'antd';

import WorkOrderForm from './WorkOrderForm';
import WorkOrderFiles from './WorkOrderFiles';
import PeopleList from './PeopleList';
import FigureList from './FigureList';
import MetadataForm from '../common/MetadataForm';
import FinalizationForm from './FinalizationForm';
// import AdditionalInfo from './AdditionalInfo'; 


const { TabPane } = Tabs;

@observer
class WorkOrderDrawer extends Component {

    close = () => {
        const store = this.props.workOrderStore;
        store.showDrawer = false;
        store.activeTab = "0";
    }

    /**
     * Allow the user to change the tab only when the primary tab has been filled and saved.
     */
    changeTab = (tabIndex) => {
        const store = this.props.workOrderStore;

        if(store.isPrimaryDirty===true) {
            message.warning("You have modified the primary information. Please save before navigating to any tab.")
            return;
        }

        if(store.workOrder.id===0) {
            message.warning("Please save the primary information before navigating to any tab.")
            return;
        }

        store.changeTab(tabIndex);
    }

    renderTab = (tabKey) => {

        switch (tabKey) {
            case "primary":
                return <WorkOrderForm workOrderStore = {this.props.workOrderStore} />

            case "files":
                return <WorkOrderFiles workOrderStore = {this.props.workOrderStore} />

            case "figures":
                return <FigureList workOrderStore = {this.props.workOrderStore} />

            case "people":
                return <PeopleList workOrderStore = {this.props.workOrderStore} />

            case "articleMetadata":
                return <MetadataForm workOrderStore = {this.props.workOrderStore} />
            
            case "finalization":
                return <FinalizationForm workOrderStore= {this.props.workOrderStore}/> 
                
            default:
                return <WorkOrderForm workOrderStore = {this.props.workOrderStore}/>

        }
    }
  
    buildTabs = (store) => {
        return (
            <Tabs activeKey={store.activeTab} tabPosition='top' style={{ height: "100%" }} onChange={this.changeTab}>
              {[...store.drawerTabs].map((menu, index) => (
                <TabPane tab={menu.label} key={index} >
                  {this.renderTab(menu.key)}
                </TabPane>
              ))}
            </Tabs>
          )

    }

    renderTabList = () => {
        const store = this.props.workOrderStore;

        if (store.fileStore.drawerLoading === true) {
            return (
              <Spin tip="Loading..." indicator={<Icon type="loading" style={{ fontSize: 24 }} spin />}>
                {this.buildTabs(store)}
              </Spin>
            )
        }
        
        return (
           this.buildTabs(store)
        )
    }

    /**
     * To Provide context sensitive help and messages to the User from the WorkOrderStore
     */
    renderFooter = () => {
        return (
            <div></div>
        )
    }

    render() {
        const store = this.props.workOrderStore;
        const title = store.workOrder.id ===0 ? 'New Request' : "Manuscript: "+store.workOrder.manuscriptNo;
        return (
            <Drawer title={title} width={"45%"} closable={true} onClose={this.close} visible={store.showDrawer} destroyOnClose>
                {this.renderTabList()}
                {this.renderFooter()}
            </Drawer>
        );
    }
}

export default WorkOrderDrawer