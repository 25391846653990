import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Icon, List, Modal,Divider } from 'antd';
import {humanizeDate } from '../stores/Util';
import s3Util from '../../components/s3Util.js';

@observer
class DuplicateFilesModal extends Component {

    deleteDuplicateFile = (item) => {
        const store = this.props.fileStore;
        let filePath = `${item.filePath}/${item.name}`;
        store.showDuplicatesModal = false;
        store.drawerLoading = true;
        s3Util._deleteFile(item, store, filePath);
    }

    downloadFile = (item) => {
        let filePath = `${item.filePath}`;
        s3Util.downloadFile(filePath);
    }

    handleOk = (store) => {
        store.showDuplicatesModal = false;
    };

    handleCancel = (store) => {
        store.showDuplicatesModal = false;
    };

    render() {

        const store = this.props.fileStore;

            return (
                <Modal visible={store.showDuplicatesModal} onOk={() => { this.handleOk(store) }} onCancel={() => { this.handleCancel(store) }} destroyOnClose={true} footer={[null, null,]}>
                <List
                    dataSource={store.duplicatedFiles}
                    renderItem={item => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                title={item.fileName}
                                description={`${humanizeDate(item.createdOn)} by ${item.fullName}`}
                            />
                            <Icon type="download" onClick={() => { this.downloadFile(item) }} key={`${item.id}Download`}/>                        
                                <Divider type="vertical" key={`${item.id}Divider`} />
                            <Icon type="delete" onClick={() => { this.deleteDuplicateFile(item, store) }} key={`${item.id}Delete`}/>
                            
                        </List.Item>
                    )}
                />
                </Modal>
            )
    }

}

export default DuplicateFilesModal;