import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { Button, Icon, Tooltip, Collapse } from 'antd';

const { Panel } = Collapse;

const validStyle = {
    margin: '2%',
    color: 'green'
}
const invalidStyle = {
    margin: '2%',
    color: 'red'
}

const content = {
    height: '50%',
    overflow: "auto",
    maxHeight: '50%'
}

const greenStyle = {
    background: "green",
    color: "white",
    margin: '2%',
    borderRadius: '5px',
    borderColor: 'green'
}

const grayStyle = {
    background: "gray",
    color: "white",
    margin: '2%',
    borderRadius: '5px',
    borderColor: 'green'
}

@observer
class FinalizationForm extends Component {
    constructor(props) {
        super(props);
    }

    handleRelease = () => {
        const workOrderStore = this.props.workOrderStore
        workOrderStore.finalizeWorkOrder();
    }

    renderValidation(context, isValid) {

        if (isValid) {
            return (
                <span style={validStyle}>
                    {context}&nbsp;
                    <Tooltip title="The information is sufficient">
                        <Icon type="check" />
                    </Tooltip>
                </span>
            )
        }
        return (
            <span style={invalidStyle}>
                {context}&nbsp;
                <Tooltip title="The information provided is not sufficient">
                    <Icon type="close" />
                </Tooltip>
            </span>
        )

    }

    render() {

        const store = this.props.workOrderStore;
        const disableFinalize = !store.canFinalize;

        const finalizeStyle = disableFinalize ? grayStyle : greenStyle;

        return (
            <div>
                {this.renderValidation("Primary Information", store.hasPrimaryData)}
                {this.renderValidation("Files Uploaded", store.hasFile)}
                {this.renderValidation("Author Details", store.hasPeople)}

                <Collapse defaultActiveKey={['1']}>
                    <Panel header= "Preview of the Finalization Mail" key="1">
                        <div style={content} dangerouslySetInnerHTML={{ __html: store.uploadSummary.previewContent }}></div>
                    </Panel>
                </Collapse>

                <Button disabled={disableFinalize} onClick={this.handleRelease} style={finalizeStyle} >Finalize</Button>
            </div>
        )

    }
}

export default FinalizationForm;