import React, { Component, Fragment } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { observer } from 'mobx-react';
import { PageHeader, Button, Badge,Icon } from 'antd';
import FigureModal from './FigureModal';


const columnDefs = [
    { headerName: 'Figure',field:'figureCode'},
    { headerName: 'Colour Print', field: 'colorPrint', valueFormatter: (params) => {return params.data.colorPrint === true ? "Yes" : "No"}},
    { headerName: 'Colour Online', field: 'colorOnline', valueFormatter: (params) => {return params.data.colorOnline === true ? "Yes" : "No"}}
];

@observer
class FigureList extends Component {
    constructor(props) {
        super(props);
    }

    next = () => {
        this.props.workOrderStore.changeTab('3');
    }

    onGridReady = (params) => {
        if (!params) {
            return;
        }

        this.gridApi = params.api;
        this.gridApi.addEventListener('rowClicked', this.figureSelected);
        params.api.sizeColumnsToFit();

        this.props.workOrderStore.figureListStore.fetchFigureList();
    }

    refresh = () => {
        this.props.workOrderStore.figureListStore.fetchFigureList();
    }

    titleHeight = () => {
        const height = window.innerHeight / 1.5;
        return { height: height };
    }

    figureSelected = (params) => {
        this.props.workOrderStore.figureStore.fetchFigure(params.data.articleWorkOrderId,params.data.id);
    }

    newFigure = () => {
        this.props.workOrderStore.figureStore.newFigure()
    }

    render() {
        const store = this.props.workOrderStore.figureListStore;
        const figureList = store.figureList;

        return (
            <Fragment>
                <PageHeader
                    extra={[
                        <Button onClick={this.newFigure} key="add">Add Figure</Button>,
                        <Button key="refresh" onClick={this.refresh} icon="reload" />,
                        <Badge key="counts" count={store.rowCount}>
                            <Button icon="mail" />
                        </Badge>
                    ]}>
                </PageHeader>
                {this.renderAgGrid()}
                {this.renderRowData(figureList)}
                
                <Button onClick={this.next} type="primary" >Next <Icon type="right" /></Button>
                <FigureModal figureStore = {this.props.workOrderStore.figureStore}/>
            </Fragment>
        );
    }

    renderAgGrid() {

        const grid_classes = ["ag-theme-balham"].join(' ');

        if (!this.params) {
            return (
                <div className={grid_classes} style={this.titleHeight()}>
                    <AgGridReact
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        enableSorting={true}
                        enableFilter={true}
                        enableColResize={true}
                    />
                </div>
            );
        }
    }

    renderRowData(figureList) {
        if (this.gridApi) {
            this.gridApi.setRowData(figureList);
        }
    }

}

export default FigureList;