import React from 'react';
import {Menu, Icon} from 'antd';
import { inject, observer } from 'mobx-react';


@inject("appStore")
@observer
class AccountMenu extends React.Component {

  render() {
    const appStore = this.props.appStore;
    return (
      <Menu>
        <Menu.Item onClick={this.handleClose}>Profile</Menu.Item>
        <Menu.Item onClick={this.handleClose}>My account</Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={appStore.logout}>
          <Icon type="logout" />
          Logout
        </Menu.Item>
      </Menu>
    );
  }
}

export default AccountMenu;