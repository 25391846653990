import React, { Component } from 'react';
import { observer } from 'mobx-react';

import {Button, Form, Input, Icon,Tooltip, InputNumber} from 'antd';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const greenStyle = {
    background: "green",
    color: "white",
    margin: '2%',
    borderRadius: '5px',
    borderColor: 'green'
};

@observer
class AdditionalInfo extends Component {

    saveAndNext = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const articleStore = this.props.articleStore;
                articleStore.drawerLoading = true;
                articleStore.createArticle(values);
            }
        });
    }

    compareToManuscriptNumber = (rule, value, callback) => {
        const articleStore = this.props.articleStore;
        if (value && value !== articleStore.article.manuscriptNo) {
          callback('Please enter the correct manuscript Number.');
        } else {
          callback();
        }
    };

    render() {
        const store = this.props.metadataStore;

        const { getFieldDecorator } = this.props.form;
        return (
            <Form {...formItemLayout} onSubmit={this.saveAndNext}>

                <Form.Item label="Word Count">
                    {getFieldDecorator  ('wordCount', {
                        initialValue: store.fieldValues['wordCount'], 
                        rules: [
                            {
                                required: true,
                                message: 'Please enter the Word Count',
                            },
                        ],
                    })(<InputNumber/>)}
                </Form.Item>

                <Form.Item label="Table Count">
                    {getFieldDecorator  ('tableCount', {
                        initialValue: store.fieldValues['tableCount'], 
                        rules: [
                            {
                                required: true,
                                message: 'Please enter the Table Count',
                            },
                        ],
                    })(<InputNumber/>)}
                </Form.Item>

                <Button disabled={false} onClick={this.saveAndNext} type="primary" style={greenStyle} >Create Project <Icon type="primary"  /></Button>
                
            </Form>
        );
    }
}

export default Form.create()(AdditionalInfo);