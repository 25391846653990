import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Button, Form, Input, Select, Row, Col, InputNumber, DatePicker, Radio, Checkbox,Icon } from 'antd';

import moment from 'moment';

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};



@observer
class MetadataForm extends Component {

    saveAndNext = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const store = this.props.workOrderStore.metadataStore;
                store.updateWorkOrderFields(values);
                this.props.workOrderStore.changeTab('4');
            }
        });
    }

    renderFormItem = (field, fieldValue, getFieldDecorator) => {
        return (
            <Form.Item key={field.id} label={field.label} >
                {this.renderField(field, fieldValue, getFieldDecorator)}
            </Form.Item>
        )
    }

    decoratorConfig = (field, fieldValue) => {
        return {
            initialValue: fieldValue[field.id],
            rules: [
                {
                    required: field.required === true,
                    message: 'This field requires an input',
                },
            ],
        }
    }

    dateDecoratorConfig = (field, fieldValue) => {
        const value = fieldValue && fieldValue[field.id] != null ? new moment(fieldValue[field.id]) : null;

        if (value != null) {
            return {
                initialValue: value,
                rules: [
                    {
                        required: field.required === true,
                        message: 'This field requires an input',
                    },
                ],
            }
        }

        return {
            rules: [
                {
                    required: field.required === true,
                    message: 'This field requires an input',
                },
            ],
        }
    }

    renderField = (field, fieldValue, getFieldDecorator) => {
        switch (field.field_type) {
            case "text":
                return getFieldDecorator(field.id, this.decoratorConfig(field, fieldValue))(<Input />)

            case "paragraph":
                return getFieldDecorator(field.id, this.decoratorConfig(field, fieldValue))(<Input.TextArea rows={4} />)

            case "number":
                return getFieldDecorator(field.id, this.decoratorConfig(field, fieldValue))(<InputNumber />)

            case "date":
                return getFieldDecorator(field.id, this.dateDecoratorConfig(field, fieldValue))(<DatePicker showTime format="DD-MMM-YYYY HH:mm:ss" />)

            case "dropdown":
                return getFieldDecorator(field.id, this.decoratorConfig(field, fieldValue))(<Select>{this.renderOptions(field.field_options.options)}</Select>)

            case "radio":
                return getFieldDecorator(field.id, this.decoratorConfig(field, fieldValue))(<Radio.Group>{this.renderRadio(field.field_options.options)}</Radio.Group>)

            case "checkboxes":
                return getFieldDecorator(field.id, this.decoratorConfig(field, fieldValue))(<Checkbox.Group style={{ width: '100%' }}><Row>{this.renderCheckboxes(field.field_options.options)}</Row></Checkbox.Group>)

            default:
                return getFieldDecorator(field.id, this.decoratorConfig(field, fieldValue))(<Input />)
        }
    }

    renderOptions = (choices) => {
        const { Option } = Select;

        return (
            choices.map(item =>
                <Option value={item.label} key={item.label} >{item.label}</Option>
            )
        )
    }

    renderCheckboxes = (choices) => {
        return (
            choices.map(item =>
                <Col key={item.label}>
                    <Checkbox value={item.label} key={item.label} >{item.label}</Checkbox>
                </Col>
            )
        )
    }

    renderRadio = (choices) => {
        return (
            choices.map(item =>
                <Radio value={item.label} key={item.label}>{item.label}</Radio>
            )
        )
    }

    render() {
        const metadataStore = this.props.workOrderStore.metadataStore;

        const fields = metadataStore.fields;
        const fieldValues = metadataStore.fieldValues;

        const { getFieldDecorator } = this.props.form;

        return (
            <Form {...formItemLayout} onSubmit={this.saveAndNext} >
                {
                    fields.map(field => (
                        this.renderFormItem(field, fieldValues, getFieldDecorator)
                    ))
                }
                <Button onClick={this.saveAndNext} type="primary" >Next <Icon type="right" /></Button>
            </Form >

        )
    }
}

export default Form.create()(MetadataForm);