import { decorate, observable, flow, action, computed } from 'mobx';
import { journalsUrl, selectedWorkOrderUrl, journalArticleTypesUrl, workflowUrl, saveWorkOrderUrl,workOrderSummaryUrl,finalizeWorkOrderUrl,isManuscriptAvailable} from './APIEndpoints';
import { isBlank,getUtcDate,isEmpty } from './Util';
import Api from './APIEndpoints';
import {appStore} from './AppStore';


const PENDING = 'pending';
const DONE = 'done';
const ERROR = 'error';

const freshWorkOrder = {
    id: 0,
    journalId: 0,
    articleTypeId: 0,
    articleCurrentStateId: 0,
    correspondenceId: 0,
    articleTypeName: '',
    journalArticleTypeId: 0,
    articleTitle: '',
    embargo:false,
    embargoDate: null,
    supplementData: false,
    embargoTimezone:'',
    linkedArticles:false,
    editorsChoice:false,
    authorEmail:'',
    firstName: '',
    middleName:'',
    lastName:'',
    notes: '',
    issueNo: '',
    volumeNo: '',
    voiceLogo: '',
    manuscriptNo: '',
    customFields:'',

}

const primaryMandatoryFields = ["articleTypeName"];

export default class WorkOrderStore {

    showDrawer = false;
    state = PENDING;
    activeTab = "0";
    showFinalizeModal = false;

    journals = [];
    articleTypes = [];
    workflows = [];

    workOrder = {};

    manuscriptNoStatus = "";
    manuscriptNoHelp = "";

    drawerTabs = [
        {
            "label": "Primary",
            "key": "primary",
        },
        {
            "label": "Files",
            "key": "files"
        },
        {
            "label": "Figures",
            "key": "figures"
        },
        {
            "label": "People",
            "key": "people",
        },
        {
            "label":"Finalization",
            "key":"finalization"
        }
    ];

    hasPrimaryData = false;
    hasFile = false;
    hasPeople = false;

    isPrimaryDirty = false;

    uploadSummary= {};

    constructor(props) {
        this.apiProxy = props.apiProxy;
        
        this.listStore = props.listStore;
        this.appStore = props.appStore;
        this.fileStore = props.fileStore;
        this.peopleListStore = props.peopleListStore;
        this.peopleStore = props.peopleStore;
        this.figureListStore = props.figureListStore;
        this.figureStore = props.figureStore;
        this.metadataStore = props.metadataStore;
        this.fetchJournals();
    }

    fetchJournals = async () => {
         this.state = PENDING;

        this.journals = [];
        this.articleTypes = [];
        this.workflows = [];

        // try {
            // const response = await this.apiProxy.getAsync(journalsUrl);
            // const data = await response.json();
            
                const params = { "where": { "userId":appStore.credentials.userId.toString()}, "limit": "5" } 
                const data = await Api.journalsUrl.get(params);

            this.journals = data;
            this.state = DONE;
        // }
        // catch (e) {
        //     this.state = ERROR;
        // }
    }

    fetchWorkOrder = flow(function* (workOrderId) {
        this.state = PENDING;

        this.articleTypes = [];
        this.workflows = [];

        this.workOrder = freshWorkOrder;

        try {
            const url = selectedWorkOrderUrl.replace('{id}', workOrderId)
            const response = yield this.apiProxy.getAsync(url);
            const data = yield response.json();
            this._setWorkOrder(data);
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        };
    });

    _setWorkOrder(data) {

        const articleTypeId = data.articleTypeId;
        const journalArticleTypeId = data.journalArticleTypeId;
        
        data.articleTypeId = articleTypeId !== null ? articleTypeId:-1;
        data.journalArticleTypeId = journalArticleTypeId !== null ? journalArticleTypeId:-1;

        if(data.manuscriptNo!== null && data.manuscriptNo!== undefined && !isBlank(data.manuscriptNo))
        {
            this.manuscriptNoStatus = "success";  
            this.manuscriptNoHelp = "";
        }

        this.fetchArticleTypes(data.journalId);
        this.fetchWorkflows(data.articleTypeId);
        this.fetchUploadedWorkOrderFiles(data.id);
        this.fetchPeople(data.id);
        this.fetchFigureList(data.id);
        this.fetchMetadata(data.id);

        this.workOrder = data; 
        this.state = DONE;

        this.isPrimaryDirty = false;
        this.activeTab = "0";
        this.showDrawer = true;
    }

    fetchUploadedWorkOrderFiles = (workOrderId) => {
        this.fileStore.workOrderId = workOrderId;
        this.fileStore.fetchUploadedWorkOrderFiles();
    }

    fetchPeople = (workOrderId) => {
        this.peopleListStore.workOrderId = workOrderId;
        this.peopleListStore.fetchPeople();
    }

    fetchFigureList = (workOrderId) => {
        this.figureListStore.workOrderId = workOrderId;
        this.figureListStore.fetchFigureList();
    }
    
    fetchMetadata = (workOrderId) => {
        this.metadataStore.fetchMetadata(workOrderId);
    }

    fetchArticleTypes = async (journalId) => {
        this.state = PENDING;
        this.workOrder.journalId = journalId;

        this.articleTypes = [];
        this.workOrder.articleTypeId = 0;

        this.workflows = [];
        this.workOrder.journalArticleTypeId = 0;

        try {
            const url = journalArticleTypesUrl.replace('{id}', journalId);
            const response = await this.apiProxy.getAsync(url);
            const data = await response.json();

            data.push({ articleTypeName: 'Others', articleTypeId: -1 });

            this.articleTypes = data;
            this.state = DONE;
        }
        catch (e) {
            this.state = ERROR;
        }
    }

    fetchWorkflows = async (articleTypeId) => {
        this.state = PENDING;
        this.workOrder.articleTypeId = articleTypeId;

        this.workflows = [];
        this.workOrder.journalArticleTypeId = 0;

        try {
            const url = workflowUrl.replace('{id}', this.workOrder.journalId).replace('{articleTypeId}', articleTypeId);
            const response = await this.apiProxy.getAsync(url);
            const data = await response.json();

            data.push({ workflowName: 'Others', id: -1 });
            this.workflows = data;
            this.workOrder.journalArticleTypeId = data[0].id;
            this.state = DONE;
        }
        catch (e) {
            this.state = ERROR;
        }
    }

    validateManuscriptNumber = async (manuscriptNumber) => {

        try {
            const url = isManuscriptAvailable.replace('{manuscriptNo}', manuscriptNumber)
            const response = await this.apiProxy.getAsync(url);
            const data = await response.json();
            this.setManuscriptStatus(data);
            this.state = DONE;
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        };

    }

    setManuscriptStatus = (data) => {

        if(data){
            this.manuscriptNoStatus = "success";  
            this.manuscriptNoHelp = "Manuscript Number is valid";
        }
        else{
            this.manuscriptNoStatus = "error";
            this.manuscriptNoHelp = "Manuscript Number already exists";
        }
    }

    newWorkOrder = () => {
        this.workOrder = freshWorkOrder;
        this.showDrawer = true;
        this.activeTab = "0";
        this.state=DONE
    }

    changeTab = (tabIndex) => {
        this.activeTab = tabIndex;

        if(this.activeTab==="4"){
            this.validate();
            this.fetchUploadSummary();
        }
    }

    validate = () => {
        this.hasPrimaryData = true;

        primaryMandatoryFields.map(fieldName => {
            const value = this.workOrder[fieldName];
            if(isBlank(value))
            {   
                this.hasPrimaryData = false;
            }
        });

        this.hasFile = this.fileStore.uploadedWorkOrderFiles.length > 0;
        this.hasPeople = this.peopleListStore.people.length > 0;
    }

    get isEditable() {
        return this.state === DONE;
    }

    get canFinalize() {
        return this.hasPrimaryData && this.hasFile && this.hasPeople
    }

    /**
     * The WorkOrder Id is a vital source to detect if it is a fresh work order or a modification to the work order
     */
    savePrimaryInfo = async (values) => {
        const customFields = {};
        customFields.voiceLogo = values.voiceLogo;
        this.workOrder.articleCurrentStateId = null;
        this.workOrder.correspondenceId = 0;
        this.workOrder.projectId = 0;
        this.workOrder.volumeNo = values.volumNo;
        this.workOrder.issueNo = values.issueNo; 
        this.workOrder.customFields = JSON.stringify(customFields);
        this.workOrder.manuscriptNo = values.manuscriptNo;
        this.workOrder.articleTitle = values.articleTitle;
        this.workOrder.journalArticleTypeId = values.journalArticleTypeId;
        this.workOrder.journalId = values.journalId;
        this.workOrder.embargo = values.embargo;
        this.workOrder.embargoDate = new Date(values.embargoDate);
        this.workOrder.embargoTimezone = values.embargoTimezone;
        this.workOrder.linkedArticles = values.linkedArticles;
        this.workOrder.linkedArticlesList = values.linkedArticlesList;
        this.workOrder.editorsChoice = values.editorsChoice;
        this.workOrder.supplementData = values.supplementData;
        this.workOrder.notes = values.notes;
        console.log({...this.workOrder});
        if(isEmpty(values.embargoDate))
        {
            this.workOrder.embargoDate = null;
        }

        this.workOrder.articleTypeName = values.customArticleType ? values.customArticleType : '';
      
        this.state = PENDING;   
      
        let utcEmbargoDate = new Date(getUtcDate(this.workOrder.embargoDate,values.embargoTimezone));

        this.workOrder.embargoDateUtc = utcEmbargoDate;

        try {
            console.log({ ...this.workOrder });
      
            const response = await this.apiProxy.asyncPost(saveWorkOrderUrl, { ...this.workOrder });
        
            const data = await response.json();
            this.workOrder.id = data.id;
            this.workOrder.articleTypeName = data.articleTypeName;
            this.fetchUploadedWorkOrderFiles(data.id);
            this.fetchPeople(data.id);
            this.fetchMetadata(data.id);
            this.fetchFigureList(data.id);
            this.listStore.fetchWorkOrders();
            this.state = DONE;
            this.isPrimaryDirty = false;
            this.activeTab='1';
        }
        catch (e) {
            this.state = ERROR;
        }
    }
   
    fetchUploadSummary = async() => {
        this.state = PENDING;

        try {
            const url = workOrderSummaryUrl.replace('{id}', this.workOrder.id)
            const response = await this.apiProxy.getAsync(url);
            const data = await response.json();
            this.uploadSummary = data;
            this.state = DONE;
        }
        catch (e) {
            this.state = ERROR;
            console.log(e);
        };
    }

    finalizeWorkOrder = async() => {
        
        this.state = PENDING;

        try {
            const url = finalizeWorkOrderUrl.replace('{id}',this.workOrder.id);
            const response = await this.apiProxy.asyncPost(url, { ...this.workOrder.id });
            const data = await response.json();
            this.listStore.fetchWorkOrders();
            this.state = DONE;
            this.showDrawer = false;
        }
        catch (e) {
            this.state = ERROR;
        }

    }
}

decorate(WorkOrderStore, {
    state: observable,
    showDrawer: observable,
    activeTab: observable,

    journals: observable,
    articleTypes: observable,
    workflows: observable,

    changeTab: action,

    workOrder: observable,

    newWorkOrder: action,

    fetchJournals: action,
    fetchArticleTypes: action,
    fetchWorkflows: action,

    savePrimaryInfo: action,
    finalizeWorkOrder: action,

    validate:action,
    hasPrimaryData:observable,
    hasFile:observable,
    hasPeople:observable,

    isPrimaryDirty:observable,
    uploadSummary:observable,
    workOrder: observable,
    articleTypeId: observable,

    manuscriptNoStatus: observable,
    manuscriptNoHelp: observable,

    canFinalize: computed,
    isEditable: computed
})